import React from "react";
// import GoogleMap from "./components/GoogleMap.component";
import MapComponent from "./components/Map.component";
import Meta from "../../components/meta";
import { INTERNAL_URLS } from "../../globals/internal-urls";
import { decodeParams } from "../../helpers/params";
// import { FacilityMarkerOptions } from "./types";
// import IEIconHackCSS from "./helpers/ie-icon-hack";
// ? Types
// import { ViewType } from "./types";
import { RouteComponentProps } from "react-router-dom";

type CovidProps = RouteComponentProps<{}>;

export const CovidPage: React.FC<CovidProps> = ({ history, location }) => {
  const searchParams = decodeParams(location.search);
  const contracts = (searchParams.contracts || "Kent & Medway - COVID-19 - Contract").split(",");
  const onFacilityClick = (marker: any) => {
    if (!marker.dashboardId) {
      return;
    }
    const siteId = marker.siteId ? marker.siteId : "primary";
    history.push({
      pathname: `${INTERNAL_URLS.systemDashboardList}/${marker.dashboardId}/${siteId}/?origin=covidMap`,
    });
  };
  return (
    <main id="ur-map" className="google-map-holder" style={{ padding: 0 }}>
      <Meta title="Covid Heatmap" hideFooter hideMeta />
      {/* <header className="content-header" style={{ top: 0, zIndex: 22 }}>
        <div className="ta-right" style={{ width: "100%" }}></div>
      </header> */}
      <MapComponent contracts={contracts} onFacilityClick={onFacilityClick} />
      {/* <style dangerouslySetInnerHTML={{ __html: IEIconHackCSS }} /> */}
    </main>
  );
};

// export default CovidPage;
