export const SERVICE_URLS = {
  "auth-service": "/auth-service",
  "user-service": "/user-service/api/v1",
  "resilience-service": "/resilience-service/api/v",
  "system-dashboard-service": "/system-dashboard-service/api/v",
  "escalation-service": "/escalation-service/api/v1",
  "nhsr-service": "/nhse-service/api/v",
  // "indicator-value-service": "/indicator-value-service/api/v",
  "fake-report-service": "/FAKE",
  "covid-service": "/might-be-added-in-future-only-in-menu",
  "elective-service": "/resilience-service/api/v", // resilience
  "vantage-service": "/resilience-service/api/v", // resilience
  "fake-tableau-service": "/FAKE2",
};

// export const LIGHTHOUSE_SERVICE_URLS = {
//   "resilience-chart-data-service": "resilience-chart-data-service/api/v1",
// };

export const PREDICTION_SERVICE_URLS = {
  "prediction-service": "/PredApi/api",
};

export const PDF_SERVICE_URLS = {
  "shrewd-pdf-generator": "/shrewd-pdf-generator",
};

export type TServiceName = keyof typeof SERVICE_URLS;
