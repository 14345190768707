// import React from "react";
import { NHSR_INTERNAL_URLS } from "../../globals/internal-urls";
//
import { HomePage } from "./pages/home";
import { DocumentsPage } from "./pages/documents";
import { WholeSystemResiliencePage } from "./pages/whole-system-resilience";
import { ReportListPage } from "../../pages/reports";

// ? TYPES:
import { RouteProps } from "react-router";

// const SamplePage = () => null;

export const nhsrRoutes: RouteProps[] = [
  {
    exact: true,
    path: NHSR_INTERNAL_URLS.nhsrhome,
    component: HomePage,
  },
  {
    path: `${NHSR_INTERNAL_URLS.nhsrdocumentOrganisationList}/:id/:pretty?`,
    component: DocumentsPage,
  },
  {
    path: `${NHSR_INTERNAL_URLS.nhsrsystemDashboardList}/:id/:siteId/:pretty?`,
    component: WholeSystemResiliencePage,
  },
  {
    path: `${NHSR_INTERNAL_URLS.nhsrotherReportOrganisationList}/:id/:type/:pretty?`,
    component: ReportListPage,
  },
];
