import React, { useState, useEffect, useRef } from "react";
import { BRAG_COLOURS, BRAG_PRESSURE_LEVEL_MAPPING } from "../globals";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { TFunction } from "i18next";
import { ChildFeature, ExpandedMarker, MapFilters, AreaPressureLevel, FilterPressureLevel } from "../types";

const TOOLTIP_WIDTH_MIN = 280;
const TOOLTIP_Y_MARGIN = 24;
const ARROW_SIZE = 8;

const UNKNOWN_FITLERS: AreaPressureLevel<FilterPressureLevel> = {
  id: "Unknown",
  filters: { total: 0 },
};

interface Props {
  currentFacilityHover: ExpandedMarker | null;
  // currentTruckHover: ExpandedMarker | null;
  currentChildHover: ChildFeature | null;
  currentParentHover: any | null;
  filters: MapFilters;
  // parentColours: AreaPressureLevel<FilterPressureLevel>[];
  childrenColours: AreaPressureLevel<FilterPressureLevel>[];
  isDragging: boolean;
  t: TFunction;
}

interface ListProps {
  filters: MapFilters;
  list: AreaPressureLevel<FilterPressureLevel>;
}

const IndicatorColourList = ({ list, filters }: ListProps): JSX.Element => {
  return (
    <div className="indicator-colour-list">
      {Object.keys(filters).map((key: string) => {
        if (typeof filters[key] === "undefined" || !filters[key].enabled || typeof list.filters[key] === "undefined") {
          return null;
        }
        const colour = BRAG_COLOURS[BRAG_PRESSURE_LEVEL_MAPPING[list.filters[key]]].colour;
        return (
          <div key={filters[key].name}>
            <span style={{ background: colour }} className="indicator-circle" />
            <span>{filters[key].name}</span>
          </div>
        );
      })}
    </div>
  );
};

const getTooltipTranslate = (
  xy: number[],
  dimensions: any,
  tooltipHeight: number,
  tooltipWidth: number,
  yMargin = 0
): {
  tooltipTranslate: string;
  arrowLeft: string;
} => {
  const TOOLTIP_WIDTH = tooltipWidth;
  let arrowLeft = "";
  let x = xy[0] - TOOLTIP_WIDTH / 2;
  // console.log(dimensions, xy, x);
  if (dimensions.width - TOOLTIP_WIDTH / 2 <= xy[0]) {
    x = dimensions.width - TOOLTIP_WIDTH;
    // ? Arrow position
    const arrowPosition = xy[0] - x - ARROW_SIZE;
    const left = TOOLTIP_WIDTH - 26 >= arrowPosition ? arrowPosition : TOOLTIP_WIDTH - 22;
    arrowLeft = `${left}px`;
  }
  // TODO: Check left side for X
  // const y = xy[1] - tooltipHeight - yMargin;
  const y = window.innerHeight - xy[1] + yMargin;
  // TODO: Maybe flip tooltip depending on position
  return {
    tooltipTranslate: `translate3d(${x}px,-${y}px,0)`,
    arrowLeft: arrowLeft,
  };
};

const MapTooltip = ({
  currentFacilityHover,
  currentChildHover,
  currentParentHover,
  // currentTruckHover,
  filters,
  // parentColours,
  childrenColours,
  // xy,
  isDragging,
  t,
}: Props): JSX.Element | null => {
  const tooltipRef = useRef(null);
  const arrowRef = useRef(null);
  const [visible, setVisible] = useState(false);
  // const [xy, setXy] = useState([0, 0]);
  //updateValue([event.clientX, event.clientY]);
  useEffect(() => {
    const isVisible = currentFacilityHover || currentChildHover || currentParentHover ? true : false;
    // if (visible !== isVisible) {
    setVisible(isVisible);
    // }
  }, [currentFacilityHover, currentChildHover, currentParentHover]);
  useEffect(() => {
    // const updateValue = throttle((value: number[]) => {
    //   setXy(value);
    // }, 1);

    const handleScroll = (event: any) => {
      const xy = [event.clientX, event.clientY];
      // updateValue(xy);
      if (tooltipRef.current !== null) {
        const positions = getTooltipTranslate(
          xy,
          {
            width: window.innerWidth,
            height: window.innerHeight,
          },
          (tooltipRef.current as any).offsetHeight,
          (tooltipRef.current as any).offsetWidth,
          TOOLTIP_Y_MARGIN
        );
        (tooltipRef.current as any).style.transform = positions.tooltipTranslate;
        (arrowRef.current as any).style.left = positions.arrowLeft;
      }
    };

    window.addEventListener("mousemove", handleScroll, true);

    return () => {
      window.removeEventListener("mousemove", handleScroll);
    };
  }, []);
  const currentParentFilterList: AreaPressureLevel<FilterPressureLevel> | undefined | null = currentParentHover; // && parentColours.find((filterList) => filterList.id === currentParentHover.groupname);
  const currentChildrenFilterList: AreaPressureLevel<FilterPressureLevel> | undefined | null =
    currentChildHover && childrenColours.find((filterList) => filterList.id === currentChildHover.properties.ccgName);
  // if (isDragging) {
  //   return null;
  // }
  // if (!visible) {
  //   return null;
  // }
  return (
    <div
      ref={tooltipRef}
      className="box map-tooltip"
      style={{
        bottom: 0,
        left: 0,
        minWidth: `${TOOLTIP_WIDTH_MIN}px`,
        width: "auto",
        pointerEvents: "none",
        visibility: visible && !isDragging ? "visible" : "hidden",
        // transform: getTooltipTranslate(xy, TOOLTIP_Y_MARGIN)
      }}>
      <div className="arrow-bottom" ref={arrowRef} />
      {currentFacilityHover && (
        <div>
          <span>{currentFacilityHover.type}: </span>
          <strong>{currentFacilityHover.name}</strong>
        </div>
      )}
      {(currentParentHover || currentChildHover) && (
        <div>
          <span>{t("STP")}: </span>
          <strong>
            {(currentParentHover && currentParentHover.groupname) ||
              (currentChildHover && currentChildHover.properties.parentName)}
          </strong>
        </div>
      )}
      {currentChildHover && (
        <div>
          <span>{t("CCG")}: </span>
          <strong>{currentChildHover.properties.ccgName}</strong>
        </div>
      )}
      {currentParentHover && (
        <IndicatorColourList list={currentParentFilterList || UNKNOWN_FITLERS} filters={filters} />
      )}
      {currentChildHover && (
        <IndicatorColourList list={currentChildrenFilterList || UNKNOWN_FITLERS} filters={filters} />
      )}
    </div>
  );
};

export default withTranslation("covid")(MapTooltip);
