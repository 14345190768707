import React, { useState, useEffect } from "react";
import useCustomCompareEffect from "../../hooks/use-custom-compare-effect";
import { useSelector } from "react-redux";
import { Button, Dropdown, Tippy, DatePicker } from "@tscore/react-components";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { updateParams } from "../../helpers/params";
import moment from "moment";
import {
  DATE_FILTER_FORMAT,
  formatToISOFromFilter,
  formatToString,
  momentDateFormat,
} from "../../lang/DateTimeFormats";
import isEqual from "lodash/isEqual";
// import { RBA_PARTIAL } from "./consts";
// ? TYPES:
import { ReportType } from "../../store/types/reports-state";
import { ApplicationState } from "../../store/reducers";
import { TFunction } from "i18next";
import { RouteComponentProps } from "react-router-dom";

interface ReportFiltersSearchParamsRaw {
  from?: string;
  to?: string;
  areaIds?: string;
}

interface ReportFiltersSearchParams {
  from?: string;
  to?: string;
  areaIds: number[];
}

interface ReportListFiltersProps extends RouteComponentProps<any> {
  reportType: ReportType;
  searchParams: ReportFiltersSearchParamsRaw;
  t: TFunction;
}

const ReportListFiltersWoT: React.FC<ReportListFiltersProps> = ({ t, searchParams, history, location }) => {
  const [currentFilters, setCurrentFilters] = useState<ReportFiltersSearchParams>({ areaIds: [] });
  const [firstAreaSelected, setFirstAreaSelected] = useState("");
  useEffect(() => {
    const { from, to, areaIds } = searchParams;
    const filters = {
      from: formatToISOFromFilter(from),
      to: formatToISOFromFilter(to, "end"),
      areaIds: areaIds ? areaIds.split(",").map((id) => +id) : undefined,
    };
    setCurrentFilters(Object.entries(filters).reduce((acc, [k, v]) => (v ? { ...acc, [k]: v } : acc), { areaIds: [] }));
  }, [setCurrentFilters, searchParams]);
  const areaList = useSelector((state: ApplicationState) => state.reportsReducer["all"].apiMeta.areaList);
  useCustomCompareEffect(
    () => {
      if (areaList.length > 0) {
        if (currentFilters.areaIds.length === 0) {
          setFirstAreaSelected("");
          return;
        }
        const found = areaList.find((o) => o.id === currentFilters.areaIds[0]);
        setFirstAreaSelected(found ? found.name : "");
      }
    },
    [setFirstAreaSelected, currentFilters.areaIds, areaList],
    isEqual
  );
  const onGenericFieldChange = (newValues: { [key: string]: string | number | boolean | undefined }) => {
    history.push({
      search: updateParams(location.search, {
        ...newValues,
        page: 1,
      }),
    });
  };
  const toggleArea = (id: number) => (e: any) => {
    e.preventDefault();
    const currentAreas = currentFilters.areaIds;
    const newAreas = currentAreas.includes(id) ? currentAreas.filter((a) => a !== id) : [...currentAreas, id];
    onGenericFieldChange({ areaIds: newAreas.length === 0 ? undefined : newAreas.join(",") });
  };
  const clearFilters = (filters: string[]) => () => {
    const filtersToClear = filters.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: undefined,
      };
    }, {});
    onGenericFieldChange(filtersToClear);
  };
  // const selectedAreaIds = Object.keys(currentFilters.areaIds);
  const areAnyFiltersEnabled = Object.keys(currentFilters).length > 1 || currentFilters.areaIds.length > 0;
  return (
    <aside className="table-filters box ta-right">
      <Dropdown
        className="table-filters__limited-dropdown"
        trigger={
          <Button
            className={classNames({
              "is-active": currentFilters.areaIds.length !== 0,
            })}
            colour="link"
            hasChevron>
            {firstAreaSelected || t("regionReports:reportListFilters.Areas")}
            {currentFilters.areaIds.length > 1 ? ` (+${currentFilters.areaIds.length - 1})` : ""}
          </Button>
        }
        position="right">
        {areaList.map((o) => (
          <li className={classNames({ inactive: !o.active })} key={o.id}>
            <a href="#!toggle-area" onClick={toggleArea(o.id)}>
              <label className="checkbox">
                <input
                  onChange={(e) => e.preventDefault()}
                  type="checkbox"
                  checked={currentFilters.areaIds.includes(o.id)}
                />
                <span>{o.name}</span>
              </label>
            </a>
          </li>
        ))}
        <li className="spacer" />
        <li className="ta-right">
          <div>
            <Button disabled={currentFilters.areaIds.length === 0} colour="link" onClick={clearFilters(["areaIds"])}>
              {t("regionReports:reportListFilters.Clear")}
            </Button>
          </div>
        </li>
      </Dropdown>
      <Dropdown
        trigger={
          <Button
            className={classNames({
              "is-active": currentFilters.from,
            })}
            colour="link"
            hasChevron>
            {t("regionReports:reportListFilters.From", {
              context: currentFilters.from ? "selected" : undefined,
              dateFormatted: moment(currentFilters.from).format(momentDateFormat),
            })}
          </Button>
        }
        position="right">
        <li>
          <DatePicker
            selected={currentFilters.from ? moment(currentFilters.from).toDate() : null}
            onChange={(date: Date) => onGenericFieldChange({ from: formatToString(date, DATE_FILTER_FORMAT) })}
            filterDate={(date: Date) => {
              const isLaterThanToday = new Date() > date;
              if (!currentFilters.to) {
                return isLaterThanToday;
              }
              // const minDate = moment(currentFilters.to).subtract(3, "months").subtract(1, "day");
              const maxDate = moment(currentFilters.to);
              return maxDate.toDate() >= date; // && minDate.toDate() <= date; // && isLaterThanToday;
            }}
            inline
          />
        </li>
        <li className="spacer" />
        <li className="ta-right">
          <div>
            <Button disabled={!currentFilters.from} colour="link" onClick={clearFilters(["from"])}>
              {t("regionReports:reportListFilters.Clear")}
            </Button>
          </div>
        </li>
      </Dropdown>
      <Dropdown
        trigger={
          <Button
            className={classNames({
              "is-active": currentFilters.to,
            })}
            colour="link"
            hasChevron>
            {t("regionReports:reportListFilters.To", {
              context: currentFilters.to ? "selected" : undefined,
              dateFormatted: moment(currentFilters.to).format(momentDateFormat),
            })}
          </Button>
        }
        position="right">
        <li>
          <DatePicker
            selected={currentFilters.to ? moment(currentFilters.to).toDate() : null}
            onChange={(date: Date) => onGenericFieldChange({ to: formatToString(date, DATE_FILTER_FORMAT) })}
            filterDate={(date: Date) => {
              const isLaterOrEqualThanToday = new Date() >= date;
              if (!currentFilters.from) {
                return isLaterOrEqualThanToday;
              }
              // const maxDate = moment(currentFilters.from).add(3, "months");
              const minDate = moment(currentFilters.from);
              return minDate.toDate() <= date && isLaterOrEqualThanToday;
              // return minDate.toDate() <= date && maxDate.toDate() >= date && isLaterOrEqualThanToday;
            }}
            inline
          />
        </li>
        <li className="spacer" />
        <li className="ta-right">
          <div>
            <Button disabled={!currentFilters.to} colour="link" onClick={clearFilters(["to"])}>
              {t("regionReports:reportListFilters.Clear")}
            </Button>
          </div>
        </li>
      </Dropdown>
      <Tippy content={t("regionReports:reportListFilters.Clear Filters")} placement="bottom">
        <div data-tooltipped>
          <Button
            disabled={!areAnyFiltersEnabled}
            onClick={clearFilters(["areaIds", "to", "from"])}
            colour="link"
            icon="playlist_remove"></Button>
        </div>
      </Tippy>
    </aside>
  );
};

export const ReportListFilters = withTranslation("regionReports")(ReportListFiltersWoT);
