import { Toast } from "@tscore/react-components";

export const generateParams = (
  params: { [key: string]: string | number | null | boolean | undefined } | null,
  trailingMark: boolean | string = true
): string => {
  if (params === null) {
    return "";
  }
  const keys = Object.keys(params).filter((key: string) => params[key] !== undefined);
  if (keys.length === 0) {
    return "";
  }
  const mark = trailingMark === true ? "?" : trailingMark || "";
  return (
    mark +
    keys
      .map((key: string) => {
        const value =
          params[key] === undefined || params[key] === null
            ? ""
            : encodeURIComponent(params[key] as string | boolean | number);
        return key + "=" + value;
      })
      .join("&")
  );
};

export const decodeParams = (searchString: string | null): any => {
  if (!searchString || searchString === "") {
    return {};
  }
  const search = searchString.substring(1);
  try {
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
      let newValue = value;
      if (key !== "") {
        try {
          newValue = decodeURIComponent(value);
        } catch {
          newValue = "";
        }
      }
      return newValue;
    });
  } catch (err) {
    console.error("Invalid URL");
    Toast(
      {
        title: "Invalid URL",
        body: "You have entered an invalid URL",
      },
      "error",
      {
        autoClose: 12000,
        toastId: "invalid-url-id",
      }
    );
    return {};
  }
};

export const updateParams = (
  currentParamsAsString: string | null,
  newParams: { [paramName: string]: string | number | boolean | null | undefined }
): string => {
  return generateParams({
    ...decodeParams(currentParamsAsString),
    ...newParams,
  });
};

export const searchUpdateQuery = (value: string | undefined, history: any, location: any) => {
  if (value === "") {
    value = undefined;
  }
  history.push({
    search: updateParams(location.search, { query: value, page: 1 }),
  });
};
