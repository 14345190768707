import React from "react";
import { useSelector } from "react-redux";

import { Tabs } from "@tscore/react-components";
import { TabLiveSystemActions } from "./tab-live-system-actions";
import { TabEscalationPlans } from "./tab-escalation-plans";
import { TabEscalationHistory } from "./tab-escalation-history";
import { TabMyLiveActions } from "./tab-my-live-actions";
import { useTranslation } from "react-i18next";

// ? TYPES:
import { ApplicationState } from "../../../store/reducers";

export const ModalEscalationActions: React.FC<{ systemDashboardId: number; pdSiteId: number }> = ({
  pdSiteId,
  systemDashboardId,
}) => {
  const { t } = useTranslation();
  const credentials = useSelector((state: ApplicationState) => state.credentialsReducer.app_user_info);
  return (
    <div id="modal-escalation-actions">
      <header>
        <h4>{t("escalation:Escalation Actions")}</h4>
        {/* <h6>{name}</h6> */}
      </header>
      <article>
        <Tabs>
          <Tabs.Item label={t("escalation:System Actions")}>
            <TabLiveSystemActions
              pdSiteId={pdSiteId}
              credentials={credentials}
              title={t("escalation:System Actions")}
            />
          </Tabs.Item>
          <Tabs.Item label={t("escalation:My Actions")}>
            <TabMyLiveActions pdSiteId={pdSiteId} credentials={credentials} title={t("escalation:My Actions")} />
          </Tabs.Item>
          <Tabs.Item label={t("escalation:Action Plans")}>
            <TabEscalationPlans
              systemDashboardId={systemDashboardId}
              pdSiteId={pdSiteId}
              credentials={credentials}
              title={t("escalation:Action Plans")}
            />
          </Tabs.Item>
          <Tabs.Item label={t("escalation:Escalation History")}>
            <TabEscalationHistory
              pdSiteId={pdSiteId}
              credentials={credentials}
              title={t("escalation:Escalation History")}
            />
          </Tabs.Item>
        </Tabs>
      </article>
      {/* <footer className="ta-right">x</footer> */}
    </div>
  );
};
