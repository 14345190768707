import { API_START, API_END, API_ERROR } from "../../actions/api.names";

import { defaultApiError } from "../default-api-error";
import { generateMenu } from "./generate-menu";
import { MENU_MAP, NHSR_MENU_MAP, ELECTIVE_MENU_MAP, VANTAGE_MENU_MAP, DEFAULT_RBA_LIST } from "./consts";
import { APPS } from "../../../components/structure/my-apps/app-list";
import { getMyAppsAccess } from "./get-my-apps-access";
import { createRba } from "./create-rba";
import { MODULE_URLS, MODULE_IDS, MODULE_IDS_SERVER } from "../../../globals/internal-urls";
import { removeLastSlashIfExists } from "../../../helpers/remove-last-slash-if-exists";
import { getFakeTableauGlobalList } from "./get-fake-tableau-global-list";
import { createExtendedMenus } from "./create-extended-menus";
// ? TYPES:
import { MenuState, ImportedAccessInfo } from "../../types/menu-state";
import { TModuleUrlKey } from "../../../globals/internal-urls";

const STATIC_MENU = {
  Resilience: [
    {
      name: "",
      menu: [MENU_MAP[""].home!],
    },
  ],
  "NHS Region": [
    {
      name: "",
      menu: [NHSR_MENU_MAP[""].nhsrhome!],
    },
  ],
  Elective: [
    {
      name: "",
      menu: [],
    },
  ],
  Vantage: [
    {
      name: "",
      menu: [],
    },
  ],
  WaitLess: [],
};

const STATIC_MENU_EXTENDED = createExtendedMenus(STATIC_MENU);

const DEFAULT_RBA = {
  "-1": DEFAULT_RBA_LIST,
};

const defaultState: MenuState = {
  myApps: APPS,
  meta: {
    module: "Resilience", // ! internal use only
    moduleId: MODULE_IDS["Resilience"],
    moduleIdServer: MODULE_IDS_SERVER["Resilience"],
    moduleClassName: "module-resilience",
    moduleUrlPrefix: "/",
    title: "-",
    breadcrumbs: [],
    isFetching: false,
  },
  rawMenu: {},
  menu: STATIC_MENU_EXTENDED,
  currentMenu: STATIC_MENU_EXTENDED["Resilience"],
  cached: false,
  rba: {
    Resilience: DEFAULT_RBA,
    "NHS Region": DEFAULT_RBA,
    Elective: DEFAULT_RBA,
    Vantage: DEFAULT_RBA,
    WaitLess: {},
  },
  escalationEnabledDashboards: {},
  isFetching: true,
  errors: {},
  customRba: {
    CAN_ACCESS_REGION_ALERTS: false,
  },
};

const getModuleName = (pathname: string): TModuleUrlKey => {
  const RESILIENCE_MODULE_NAME = "Resilience";
  // https://fettblog.eu/typescript-better-object-keys/
  return (
    (Object.keys(MODULE_URLS) as TModuleUrlKey[]).find(
      (key: TModuleUrlKey) =>
        key !== RESILIENCE_MODULE_NAME && pathname.startsWith(removeLastSlashIfExists(MODULE_URLS[key]))
    ) || RESILIENCE_MODULE_NAME
  );
};

const getEscalationEnabledDashboards = (ids: number[]) =>
  ids.reduce((final: { [dashboardId: string]: boolean }, current: number) => {
    final[current] = true;
    return final;
  }, {});

const menuReducer = (state: MenuState = defaultState, action: any): MenuState => {
  switch (action.type) {
    case API_START: {
      if (action.payload === "GET_MENU") {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      if (action.payload === "GET_MENU") {
        return {
          ...state,
          isFetching: false,
        };
      }
      return state;
    }
    case "GET_MENU": {
      const tableauGlobalList = getFakeTableauGlobalList();
      const baseDataNhsr = action.data["nhsr-service"] || {};
      const dataNhsr = {
        ...baseDataNhsr,
        tableauGlobalList,
      };
      const dataWithFake = {
        ...action.data,
        "nhsr-service": dataNhsr,
        "fake-report-service": {
          otherReportOrganisationList: dataNhsr.otherReportOrganisationList,
          winterReportOrganisationList: dataNhsr.winterReportOrganisationList,
        },
        "fake-tableau-service": {
          tableauGlobalList,
        },
      };
      const menu = generateMenu(MENU_MAP, dataWithFake as ImportedAccessInfo);
      const nhsrMenu = generateMenu(NHSR_MENU_MAP, dataWithFake as ImportedAccessInfo);
      // TODO: flat menu?
      // TODO: e.g. escalation enabled etc
      const electiveMenu = generateMenu(ELECTIVE_MENU_MAP, action.data as ImportedAccessInfo);

      const { "escalation-service": ignore1, "nhsr-service": ignore2, ...resilienceRbaInput } = action.data;
      const flattenedResilienceRbaInput: { [key: string]: any } = Object.values(resilienceRbaInput).reduce(
        (final: { [key: string]: any }, current: any) => ({
          ...final,
          ...current,
        }),
        {}
      );
      const newRba = {
        // ...state.rba,
        "NHS Region": createRba({ ...DEFAULT_RBA }, dataNhsr),
        Resilience: createRba({ ...DEFAULT_RBA }, flattenedResilienceRbaInput),
        Elective: {},
        Vantage: {},
        WaitLess: {},
      };
      return {
        ...state,
        cached: action.cached,
        menu: createExtendedMenus({
          ...state.menu,
          Resilience: menu,
          "NHS Region": nhsrMenu,
          Elective: electiveMenu,
          Vantage: generateMenu(VANTAGE_MENU_MAP, action.data as ImportedAccessInfo),
        }),
        escalationEnabledDashboards: getEscalationEnabledDashboards(
          action.data["escalation-service"].escalationEnabledSystemDashboardList
        ),
        rba: newRba,
        myApps: getMyAppsAccess(action.data, state.myApps, action.productList || []),
        rawMenu: action.data,
        customRba: {
          ...state.customRba,
          CAN_ACCESS_REGION_ALERTS: Object.values(newRba["NHS Region"]).some((obj) => {
            return Object.entries(obj).some(
              ([role, canAccess]) =>
                canAccess === true &&
                ["REPORT_SUBMITTER", "REPORT_REQUESTER", "REPORT_FILLER"].some((endRole) => role.endsWith(endRole))
            );
          }),
        },
      };
    }
    case "SET_SITE_MODULE": {
      const module = getModuleName(action.pathname);
      return {
        ...state,
        meta: {
          ...state.meta,
          module,
          moduleId: MODULE_IDS[module],
          moduleIdServer: MODULE_IDS_SERVER[module],
          moduleClassName: "module-" + module.toLowerCase().replace(" ", "-"),
          moduleUrlPrefix: MODULE_URLS[module],
        },
      };
    }
    case "SET_SITE_META": {
      return {
        ...state,
        meta: {
          ...state.meta,
          title: action.title,
          breadcrumbs: action.breadcrumbs,
          isFetching: action.isFetching,
          hideModuleName: action.hideModuleName,
        },
      };
    }
    default:
      return state;
  }
};

export default menuReducer;
