import { accessEnv } from "../access-env";

export const thirdPartyCookieTest = (
  callBack: (thirdPartyCookiesEnabled: boolean) => void,
  iFrameUri = accessEnv("COOKIE_TEST_IFRAME_URL", "")
) => {
  const messageHandler = (event: any) => {
    // check for trusted origins here
    const data = JSON.parse(event.data);
    callBack(data["result"]);
    window.removeEventListener("message", messageHandler);
    document.body.removeChild(frame);
  };
  window.addEventListener("message", messageHandler);
  const frame: any = document.createElement("iframe");
  frame.src = iFrameUri;
  frame.sandbox = "allow-scripts allow-same-origin";
  frame.style = `display:none`;
  frame.onload = (_e: any) => {
    frame.contentWindow.postMessage(JSON.stringify({ test: "cookie" }), "*");
  };
  document.body.appendChild(frame);
};
