import React from "react";
import { Polygon } from "../../../../components/map/improved/Polygon";
import { MultiPolygon } from "../../components/MultiPolygon.component";
import { mapCoordinatesToPolygon, getPolygonOpacity } from "../../helpers/map";
import { BRAG_COLOURS } from "../../globals";
import { PARENT_POLYGON_OPACITY, defaultParentPolygonOptions } from "../../globals/settings";
// ? TYPES:
import { ParentFeature } from "../../types";

const SHAPES: any = {
  Polygon: Polygon,
  MultiPolygon: MultiPolygon,
};

interface Props {
  parent: any[];
  setErrors: Function;
  // parentColours: AreaPressureLevel<FilterPressureLevel>[];
  currentParentHover: ParentFeature | null;
  onMouseout?: Function;
  onPolygonMouseMove?: Function;
  onMousemove?: Function;
}

const countiesList = ({
  parent,
  // parentColours,
  setErrors,
  currentParentHover,
}: Props): JSX.Element[] | null => {
  if (!parent) {
    return null;
  }
  return parent.map((feature: any) => {
    return feature.parentGeoJson.geometries.map((geometry: any) => {
      // return geometries.map((feature: any, index: number) => {
      const id = feature.groupname;
      let coordinates = [];
      if (geometry.type === "MultiPolygon") {
        coordinates = geometry.coordinates;
      } else {
        coordinates = mapCoordinatesToPolygon(geometry.coordinates);
      }
      const Shape = SHAPES[geometry.type];
      if (!Shape) {
        console.error("Shape doesn't exist");
        setErrors({ "Wrong Shape": `${geometry.type} config doesn't exist` });
      }
      const fillOpacity = getPolygonOpacity(currentParentHover, feature, PARENT_POLYGON_OPACITY);
      // const areaFilters = parentColours.find(
      //   (item: AreaPressureLevel<FilterPressureLevel>) => item.id === feature.groupname
      // );
      // const pressureLevel = areaFilters ? (areaFilters as AreaPressureLevel<FilterPressureLevel>).filters.total : 0;
      // const fillColor = BRAG_COLOURS[BRAG_PRESSURE_LEVEL_MAPPING[pressureLevel]].colour;
      const fillColor = BRAG_COLOURS.black.colour;
      return (
        <Shape
          key={id}
          id={id}
          name={id}
          zIndex={3}
          clickable={false}
          // name={feature.properties.ccg16nm}
          paths={coordinates}
          {...defaultParentPolygonOptions}
          fillColor={fillColor}
          fillOpacity={fillOpacity}
          // onMouseover={(e: any, b: any, MouseEvent: any) => this.onParentMouseOver(e, feature)}
          // onMouseout={(e: any, b: any, MouseEvent: any) => onMouseout(e)}
          // onMousemove={(e: any, b: any, MouseEvent: any) => onPolygonMouseMove(e, feature, MouseEvent, onMousemove)}
          // onClick={(e: any) => this.handleChildClick(e)}
        />
      );
    });
  });
};

export default countiesList;
