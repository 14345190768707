// ? TYPES:
import { MapOptions } from "../types";

// export const GOOGLE_MAP_URL = "https://maps.googleapis.com/maps/api/js";

export const BOUNDS_ZOOM_RELATIVE_TO_MAX = -1;
// export const MAX_ZOOM = 10;

export const mapSettings: MapOptions = {
  zoom: 7,
  disableDefaultUI: true,
  maxZoom: 10,
  minZoom: 7,
  zoomControl: true,
  mapTypeControl: false,
};

// export const initialCenter: MapOptions["center"] = {
//   lat: 51.5074,
//   lng: 0.1278,
// };
export { initialCenter } from "../../../components/map/globals";
// $brag-map: (
//   brag-green: #00ca72,
//   brag-amber: #fda136,
//   brag-red: #e0394e,
//   brag-black: #212a41,
//   brag-blue: #2a92e9,
//   brag-gray: #ddd,
//   brag-white: #fff
// );
// const COLOUR_WHITE = "#ffffff";
// export const BRAG_COLOURS: any = {
//   green: {
//     colour: "#00ca72",
//     textColour: COLOUR_WHITE,
//   },
//   amber: {
//     colour: "#fda136",
//     textColour: COLOUR_WHITE,
//   },
//   red: {
//     colour: "#e0394e",
//     textColour: COLOUR_WHITE,
//   },
//   black: {
//     colour: "#212a41",
//     textColour: COLOUR_WHITE,
//   },
//   gray: {
//     colour: "#9fa3a9",
//     textColour: "#00112b",
//   },
//   white: {
//     colour: COLOUR_WHITE,
//     textColour: "#00112b",
//   },
// };

// export const BRAG_PRESSURE_LEVEL_MAPPING: any = {
//   "-1": "white",
//   0: "gray",
//   1: "green",
//   2: "amber",
//   3: "red",
//   4: "black",
//   5: "white", // just in case
// };

export { BRAG_PRESSURE_LEVEL_MAPPING } from "../../../helpers/brag-helpers";
export { BRAG_COLOURS } from "../../../components/map/consts";
// export enum FACILITY_TYPE_TO_TEXT_MAPPING {
//   Hospital = "H",
//   hospital = "H",
//   MIU = "MIU",
//   WIC = "WIC",
//   UTC = "UTC",
// }
