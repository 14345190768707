import React from "react";
import Meta from "../../components/meta";
import { Tabs } from "@tscore/react-components";
// import classNames from "classnames";
// import { TAB_NAMES } from "./consts";
import { TabEditProfile } from "./tab-edit-profile";
import { TabChangePassword } from "./tab-change-password";
// import { TabEscalationAlerts } from "./tab-escalation-alerts";
import { Alerts } from "./alerts";
import { useTranslation } from "react-i18next";
//? TYPES:
// import { ApplicationState } from "../../store/reducers";
// import { TTabName } from "./consts";
import { RouteComponentProps } from "react-router-dom";

const TABS = ["edit-profile", "change-password", "notification-settings"] as const;
type TTabName = (typeof TABS)[number];

interface Params {
  type: TTabName;
}

type MyAccountPageProps = RouteComponentProps<Params>;
// interface MyAccountPageProps {}

export const MyAccountPage: React.FC<MyAccountPageProps> = ({ match, history, location }) => {
  const { t } = useTranslation();
  const type = match.params.type || TABS[0];

  // const dispatch = useDispatch();
  const onClickTabItem = (tab: { label: string; id?: string }) => {
    history.push({
      pathname: history.location.pathname.replace(/\/[^/]*$/, "/" + tab.id!),
    });
    return false;
  };
  return (
    <main id="my-account">
      <Meta title={t("myAccount:My Account")} />
      <div>
        <Tabs isVertical activeTab={t("myAccount:" + type)} tabClicked={onClickTabItem}>
          <Tabs.Item icon="account_circle" label={t("myAccount:" + TABS[0])} id={TABS[0]}>
            <TabEditProfile />
          </Tabs.Item>
          <Tabs.Item icon="lock_open" label={t("myAccount:" + TABS[1])} id={TABS[1]}>
            <TabChangePassword />
          </Tabs.Item>
          <Tabs.Item icon="notifications_none" label={t("myAccount:" + TABS[2])} id={TABS[2]}>
            <Alerts history={history} location={location} match={match} />
          </Tabs.Item>
        </Tabs>
      </div>
    </main>
  );
};
