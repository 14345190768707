import React, { useState } from "react";
// import classNames from "classnames";
import { BRAG_PRESSURE_LEVEL_MAPPING } from "../globals";
import { BRAG_BG_COLOUR_MAPPING } from "../../../helpers/brag-helpers";
// ? TYPES:
import {
  ExpandedMarker,
  FacilityMarkerOptions,
  ChildFeature,
  AreaPressureLevelCCG,
  FilterPressureLevel,
} from "../types";
import { IndicatorInfo, IndicatorInfoCCG } from "../types/indicator-info";

interface Props {
  currentFacilityHover: ExpandedMarker | null;
  childrenColours: AreaPressureLevelCCG<FilterPressureLevel>[];
  currentCCGHover: ChildFeature | null;
  facilities: FacilityMarkerOptions[];
  isMapTooltip?: boolean;
  onFacilityClose?: (event: any) => void;
}

const TRENDS: { [key: string]: { colour: string; icon: string } } = {
  "-1": {
    // colour: "red",
    colour: "blue",
    icon: "trending_down",
  },
  "0": {
    // colour: "gray",
    colour: "blue",
    icon: "trending_flat",
  },
  "1": {
    // colour: "green",
    colour: "blue",
    icon: "trending_up",
  },
};

const IndicatorTrend: React.FC<{ trend: IndicatorInfo["trend"] }> = ({ trend }) => {
  if (trend === null || trend === undefined) {
    return null;
  }
  return (
    <li className="cell">
      <i className={`material-icons color-${TRENDS[trend].colour}`}>{TRENDS[trend].icon}</i>
    </li>
  );
};

const getBragColourClassesOrStyle = (
  defaultClasses: string,
  indicator: IndicatorInfo
): { className: string; style?: any } => {
  if (indicator.colorCodeBg && BRAG_BG_COLOUR_MAPPING[indicator.colorCodeBg.toLowerCase()]) {
    return { className: `${defaultClasses} brag-${BRAG_BG_COLOUR_MAPPING[indicator.colorCodeBg.toLowerCase()]}` };
  }
  const defaultClass = `${defaultClasses} brag-${BRAG_PRESSURE_LEVEL_MAPPING["-1"]}`;
  if (indicator.colorCodeBg) {
    return {
      style: { backgroundColor: indicator.colorCodeBg },
      className: defaultClass,
    };
  }
  if (indicator.pressureLevel !== null) {
    // ? this will also gray out, as pressureLevel 0 === gray
    return { className: `${defaultClasses} brag-${BRAG_PRESSURE_LEVEL_MAPPING[indicator.pressureLevel]}` };
  }
  return { className: defaultClass };
};

export const HospitalTooltip: React.FC<Props> = ({
  currentFacilityHover,
  facilities,
  onFacilityClose,
  currentCCGHover,
  childrenColours,
}) => {
  const [maximized, setMaximized] = useState(true);
  if (!currentFacilityHover && !currentCCGHover) {
    return null;
  }
  let facility = null;
  if (currentFacilityHover) {
    facility = facilities.find((f: FacilityMarkerOptions) => f.title === currentFacilityHover.name);
  } else if (currentCCGHover) {
    //
    facility = childrenColours.find(
      (c: AreaPressureLevelCCG<FilterPressureLevel>) => c.id === currentCCGHover.properties.ccgName
    );
  }
  if (!facility) {
    return null;
  }
  const minimizedStyle = {
    maxHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    transition: "max-height 0.2s ease-in-out, padding 0.2s ease-in-out",
  };
  return (
    <aside className="map-overlay hospital-info box">
      <header>
        <h3>
          <span>
            {(facility as FacilityMarkerOptions).title || (facility as AreaPressureLevelCCG<FilterPressureLevel>).id}
          </span>
          <span>
            <i onClick={() => setMaximized(!maximized)} className="hospital-close material-icons pointer">
              {maximized ? "expand_less" : "expand_more"}
            </i>
            <i
              onClick={(e) => {
                if (onFacilityClose) {
                  onFacilityClose(e);
                }
                setMaximized(true);
              }}
              className="hospital-close material-icons pointer">
              close
            </i>
          </span>
        </h3>
      </header>
      <section style={maximized ? { transition: minimizedStyle.transition } : minimizedStyle}>
        {/* <h4>{facility.title}</h4> */}
        {!facility.indicatorInfoDtos || facility.indicatorInfoDtos.length === 0 ? (
          <div style={{ margin: "0.6rem 0" }}>No indicators available</div>
        ) : (
          <div className="table corona-brag-table condensed">
            <div className="tbody">
              {(facility.indicatorInfoDtos as any[]).map((indicator: IndicatorInfo | IndicatorInfoCCG, i: number) => (
                <ul className="row" key={i}>
                  <li className="cell">{(indicator as IndicatorInfoCCG).nameAcronym || indicator.name}</li>
                  <li className="cell">
                    <span
                      {...getBragColourClassesOrStyle("brag corona-brag-mini", indicator)}
                      // className={`brag corona-brag-mini brag-${
                      //   indicator.pressureLevel === null
                      //     ? BRAG_PRESSURE_LEVEL_MAPPING["-1"]
                      //     : BRAG_PRESSURE_LEVEL_MAPPING[indicator.pressureLevel]
                      // }`}>
                    >
                      {indicator.value}
                    </span>
                  </li>
                  <IndicatorTrend trend={indicator.trend} />
                </ul>
              ))}
            </div>
          </div>
        )}
      </section>
    </aside>
  );
};
