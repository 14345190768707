import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
// import { defaultApiSuccess } from "../default-api-success";
import moment from "moment";
import { getCurrentLang, is24Hour } from "../../../i18n";
// import { MOMENT_ISH_API_DATE_TIME_FORMAT } from "./generate-config";
import {
  CHART_COLOURS,
  DISABLE_ANIMATION_AFTER_X_RECORDS,
  X_AXES,
} from "../../../components/indicator-history/graph-options";
import merge from "lodash/merge";
import uniq from "lodash/uniq";
import { downloadFile } from "../../../helpers/download-file";
import { generateConfig } from "./generate-config";
import { generateOptions } from "./generate-options";
import { IndicatorHistoryState } from "../../types/indicator-history-state";
import { IndicatorHistoryBulkResponse } from "../../types/indicator-history-state";
import { AnyIndicator } from "../../../types/indicator";
import { MomentDate } from "../../../types/generic";
import { TickOptions } from "chart.js";

const defaultState: IndicatorHistoryState = {
  chartData: {
    datasets: [],
  },
  isFetching: {
    GET_GRAPH_CONFIG: true,
  },
  errors: {},
  errorKey: null,
  didInvalidate: {}, // not used
  noData: true,
  disableAnimation: false,
  xAxes: X_AXES.time(),
  options: undefined,
  config: {
    freqOptions: [],
    aggregation: {},
    period: {},
    graphType: {},
    defaults: {
      aggregation: {
        // Daily: {
        //   id: -1,
        //   value: "max",
        //   label: "Maximum",
        //   defaultSelected: false,
        // },
      },
      freqOptions: {
        id: -1,
        value: "daily",
        label: "Daily",
        defaultSelected: true,
      },
      period: {
        Daily: {
          id: -1,
          value: { start: null, end: null },
          label: "" as "Custom",
          defaultSelected: true,
        },
      },
      graphType: {
        Daily: {
          id: -1,
          value: "bar",
          label: "Bar",
          defaultSelected: true,
        },
      },
    },
  },
};

const FORMATS = {
  24: {
    hourFormat: "H:mm",
    tooltipFormatWithTime: "DD MMM YYYY HH:mm",
    tooltipFormatDay: "DD MMM YYYY",
  },
  12: {
    hourFormat: "h:mm a",
    tooltipFormatWithTime: "DD MMM YYYY hh:mm A",
    tooltipFormatDay: "DD MMM YYYY",
  },
} as const;

const MAX_LABELS = 32;

const tickCallback = (
  value: string | number,
  index: number,
  ticks: any[],
  getLabelForValue: (timestamp: string | number) => string
  // format: (typeof FORMATS)[keyof typeof FORMATS]
): ReturnType<TickOptions["callback"]> => {
  const length = ticks.length;
  if (index === 0 && length === 1) {
    // not sure why not showing;
    return undefined;
    // return value;
  }
  if (index === 0) {
    // shows first value;
    return getLabelForValue(value);
  }
  if (length < MAX_LABELS) {
    return getLabelForValue(value);
  }
  if (length === index + 1) {
    return getLabelForValue(value);
  }
  const everyXTicks = Math.ceil(length / MAX_LABELS);
  const j = index % (everyXTicks + 1);
  if (j === everyXTicks && length - j > index + 1) {
    return getLabelForValue(value);
  }
  return undefined;
};

const generateLabels = (start: MomentDate, end: MomentDate, intervalMins = 15): MomentDate[] => {
  const labels = [start];
  // let n = 0;
  let currentDate = start.clone();
  while (end.diff(currentDate) > 0) {
    currentDate = currentDate.clone().add(intervalMins, "minutes");
    labels.push(currentDate.clone());
  }
  return labels;
};

const indicatorHistory = (state: IndicatorHistoryState = defaultState, action: any): IndicatorHistoryState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(
        state,
        action,
        true,
        ["GET_INDICATORS_HISTORY", "DOWNLOAD_INDICATOR_GRAPH_USER_VALUE_REPORT", "GET_GRAPH_CONFIG"],
        true,
        true
      );
    }
    case API_ERROR: {
      if (action.payload.label === "GET_INDICATORS_HISTORY") {
        return defaultApiError(
          {
            ...state,
            errorKey: action.errorKey,
            chartData: defaultState.chartData,
            noData: true,
          },
          action
        );
      }
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(
        state,
        action,
        false,
        ["GET_INDICATORS_HISTORY", "DOWNLOAD_INDICATOR_GRAPH_USER_VALUE_REPORT", "GET_GRAPH_CONFIG"],
        true
      );
    }
    case "GET_INDICATORS_HISTORY": {
      const frequency = action.params.freq;
      const language = getCurrentLang();
      const format = is24Hour(language) ? FORMATS[24] : FORMATS[12];
      // const indicators: AnyIndicator[] = action.indicators;
      const indicatorNames: [number, string][] = (action.indicators as AnyIndicator[]).map(
        ({ indicatorId, indicatorName }) => [indicatorId, indicatorName]
      );
      const indicatorMap = Object.fromEntries(indicatorNames);
      const initialEmpties = Object.fromEntries(indicatorNames.map(([indicatorId]) => [indicatorId, []]));
      const listMap: IndicatorHistoryBulkResponse["data"] = {
        ...initialEmpties,
        ...action.data,
      };
      const entries = Object.entries(listMap);
      let maxRecordsPerDataset = 0;
      const dates: { [index: string]: { [date: string]: number } } = {};
      const listOfAllDateXAxisWithDuplicates: string[] = [];
      // const skipped = (ctx: any, value: string) => {
      //   console.log({ ctx });
      //   const { curr, next } = { curr: isEmpty(ctx.p0.parsed.y), next: isEmpty(ctx.p1.parsed.y) };
      //   const originalColor = ctx.p0.options.borderColor;
      //   console.log({ curr, next });
      //   if (curr || next) {
      //     console.log("xd?");
      //     return value;
      //   }
      //   return originalColor;
      // };
      const data = entries.map(([key, data], index) => {
        const id = parseFloat(key);
        const label = indicatorMap[key] || `Indicator ${id}`;
        const datasetLength = data.length;
        if (datasetLength > maxRecordsPerDataset) {
          maxRecordsPerDataset = datasetLength;
        }
        return {
          // ...data,
          id,
          xAxisID: "x",
          label,
          showLine: true, // for line
          fill: false, // for line
          borderColor: CHART_COLOURS[index] || "red", // for line
          backgroundColor: CHART_COLOURS[index] || "red",
          // segment: {
          //   borderColor: (ctx: any) => skipped(ctx, "red"),
          // },
          // spanGaps: frequency === "trend" ? true : undefined,
          spanGaps: true,
          skipNull: true,
          data: data.map((entry) => {
            const name = entry.datetime.substring(0, 10);
            listOfAllDateXAxisWithDuplicates.push(frequency === "daily" ? name : entry.datetime);
            if (!dates[index]) {
              dates[index] = {};
            }
            dates[index][name] = dates[index][name] ? dates[index][name] + 1 : 1;
            return {
              // x: new Date(entry.datetime),
              x: entry.datetime,
              y: parseFloat(entry.value),
            };
          }),
        };
      });
      const uniqueListOfAllDateXAxis = uniq(listOfAllDateXAxisWithDuplicates).sort();
      const dataWithAllIndexes = data.map((ds) => {
        return {
          ...ds,
          data: uniqueListOfAllDateXAxis.map((datetime) => {
            const found = ds.data.find((item) => {
              if (frequency === "daily") {
                return item.x.slice(0, 10).startsWith(datetime);
              }
              return item.x === datetime;
            });
            if (found) {
              return found;
            }
            return {
              x: datetime,
              y: null,
            };
          }),
        };
      });

      const totalNumber = data.reduce((final: number, current) => final + current.data.length, 0);
      // console.log({ action, maxRecordsPerDataset, totalNumber, dates, largestNumberOfDifferentDaysInAllIndicators });
      const largestNumberOfDifferentDaysInAllIndicators = Object.values(dates).reduce((final: number, current) => {
        const currentLength = Object.keys(current).length;
        if (final < currentLength) {
          return currentLength;
        }
        return final;
      }, 0);
      const getMinUnit = (freq: "daily" | "hourly" | "trend"): "day" | undefined => {
        if (freq === "hourly") {
          return undefined;
        }
        if (freq === "daily") {
          return "day";
        }
        return largestNumberOfDifferentDaysInAllIndicators >= 3 ? "day" : undefined;
      };
      const forceMinUnit = getMinUnit(frequency);
      const isOldPc = true;
      //
      // ! midnight to midnight 2 trends or custom of =< 2 days trends will show this
      const momentStart = moment(action.params.start);
      const momentEnd = moment(action.params.end);
      const isSpecialTrend = frequency === "trend" && momentEnd.diff(momentStart, "days") <= 2;
      const labels = isSpecialTrend ? generateLabels(momentStart, momentEnd) : undefined;
      const formatter = (timestamp: string | number) => moment(timestamp).format(format.hourFormat);
      const extraTimeProps = isSpecialTrend
        ? {
            distribution: "linear" as const,
            ticks: {
              // maxRotation: 54,
              // minRotation: 20,
              // beginAtZero: true,
              // autoSkip: false,
              // min: moment(action.params.start, MOMENT_ISH_API_DATE_TIME_FORMAT).toISOString(),
              // max: moment(action.params.end, MOMENT_ISH_API_DATE_TIME_FORMAT).toISOString(),
              callback: (a, b, c) => tickCallback(a, b, c, formatter),
              display: true,
              source: "labels",
            } as Partial<TickOptions>,
          }
        : {
            ticks: {
              // callback: (value: string) => {
              //   console.log({ value });
              //   return value;
              // },
            },
          };
      const round = frequency === "daily" ? "day" : undefined;
      const xAxesTime = {
        minUnit: forceMinUnit,
        round,
        unit: round ? round : "hour",
        tooltipFormat: frequency === "daily" ? format.tooltipFormatDay : format.tooltipFormatWithTime,
        displayFormats: {
          hour: format.hourFormat,
          minute: format.hourFormat,
        },
      };
      const xAxes =
        maxRecordsPerDataset > 1
          ? X_AXES.time(merge({}, { time: xAxesTime }, extraTimeProps) as any)
          : X_AXES.time(merge({}, { ticks: { fontColor: "transparent" }, time: xAxesTime }, extraTimeProps) as any);
      const disableAnimation = isOldPc && totalNumber > DISABLE_ANIMATION_AFTER_X_RECORDS ? true : false;
      const options = generateOptions(action, {
        showLegend: dataWithAllIndexes.length > 1 ? true : false,
        disableAnimation,
        xAxes,
        is24HourLanguage: is24Hour(language),
        maxRecordsPerDataset,
      });
      return {
        ...state,
        chartData: {
          ...(labels && { labels }),
          datasets: dataWithAllIndexes,
        },
        // noData: entries.filter(([_key, data]) => data.length !== 0).length > 0 ? false : true,
        noData: maxRecordsPerDataset === 0,
        disableAnimation,
        errors: {},
        errorKey: null,
        options,
        xAxes,
      };
    }
    case "CLEANUP_INDICATORS_HISTORY": {
      // return defaultState;
      return {
        ...defaultState,
        // ? THIS WILL NOT FETCH GRAPH CONFIG AGAIN, it will use cached version.
        isFetching: {
          GET_GRAPH_CONFIG: false,
        },
        config: state.config,
      };
    }
    case "GET_GRAPH_CONFIG": {
      // console.log({ GET_GRAPH_CONFIG: action });
      return {
        ...state,
        config: generateConfig(action.data),
      };
    }
    case "DOWNLOAD_INDICATOR_GRAPH_USER_VALUE_REPORT": {
      downloadFile(action.data, action.fileName + ".xlsx");
      return state;
    }
    default:
      return state;
  }
};

const indicatorHistoryByComparisonKey = (
  state: { [comparisonKey: string]: IndicatorHistoryState } = { "-1": defaultState },
  action: any
): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_INDICATORS_HISTORY":
    case "CLEANUP_INDICATORS_HISTORY":
    case "DOWNLOAD_INDICATOR_GRAPH_USER_VALUE_REPORT":
    case "GET_GRAPH_CONFIG":
      if (!action.comparisonKey) {
        return state;
      }
      return {
        ...state,
        [action.comparisonKey]: indicatorHistory(state[action.comparisonKey], action),
      };
    case "GET_INDICATORS_HISTORY_END":
      return state;
    default:
      return state;
  }
};

export default indicatorHistoryByComparisonKey;
