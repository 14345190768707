import React from "react";
interface Props {
  errors: { [key: string]: string };
  onClose: () => void;
}

const ErrorsComponent = ({ errors, onClose }: Props): JSX.Element | null => {
  if (Object.keys(errors).length === 0 && errors.constructor === Object) {
    return null;
  }
  return (
    <div className="Toastify">
      <div
        className="Toastify__toast-container Toastify__toast-container--bottom-right"
        style={{ position: "absolute" }}>
        <div className="Toastify__toast Toastify__toast--error">
          <div role="alert" className="Toastify__toast-body">
            <div>
              <h3>Oops</h3>
              <ul>
                {Object.keys(errors).map((key: any) => {
                  return (
                    <li key={key}>
                      <p>
                        <strong>{key}:</strong> {(errors as any)[key]}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <button
            className="Toastify__close-button Toastify__close-button--error"
            type="button"
            aria-label="close"
            onClick={onClose}>
            ✖
          </button>
        </div>
      </div>
    </div>
    // <aside className="box map-errors">
    //   <header>
    //     <h3>Errors</h3>
    //   </header>
    //   <section>
    // {Object.keys(errors).map((key: any) => {
    //   return (
    //     <div key={key}>
    //       {key}: {(errors as any)[key]}
    //     </div>
    //   );
    // })}
    //   </section>
    // </aside>
  );
};

export default ErrorsComponent;
