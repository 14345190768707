// ? TYPES:
import { MapFilters, OpacityList } from "../types";

export const OPTIMIZATION_SETTINGS: any = {
  turnOffHoverOutOtherPolygons: true,
};

export const INITIAL_OTHER_OPTIONS: MapFilters = {
  facilities: { name: "Treatment Centres", enabled: true, hidden: false },
};

export const HOVER_OUT_DELAY = 30;
// export const HOVER_OVERHEAD = 160;

export const CHILD_SHADE_FOR_COUNTY = 0.5;

export const FACILITY_ICON_SIZE: {
  STROKE_WIDTH: number;
  WIDTH: number;
  BOX_OFFSET: number;
  BOX_HEIGHT: number;
} = {
  STROKE_WIDTH: 1,
  WIDTH: 22,
  BOX_OFFSET: 6,
  BOX_HEIGHT: 18,
};

export const CHILD_POLYGON_OPACITY: OpacityList = {
  ON_HOVER: 0.9,
  DEFAULT: 0.7,
  OTHER_HOVERED: 0.5,
};

export const PARENT_POLYGON_OPACITY: OpacityList = {
  ON_HOVER: 0,
  DEFAULT: 0,
  OTHER_HOVERED: 0.5,
};

export const defaultChildPolygonOptions = {
  // fillColor: "blue",
  // fillOpacity: 0.7,
  strokeColor: "#FFFFFF",
  strokeOpacity: 0.8,
  strokeWeight: 2,
};

export const defaultParentPolygonOptions = {
  // fillColor: "blue",
  // fillOpacity: 0.7,
  strokeColor: "#27303B",
  strokeOpacity: 0.8,
  strokeWeight: 2,
};

// export const defaultTacticalPolygonOptions = {
//   fillColor: "transparent",
//   fillOpacity: 1,
//   strokeColor: "#4C91E3",
//   strokeOpacity: 1,
//   strokeWeight: 1
// };
