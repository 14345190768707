import { TABLEAU_GLOBAL_REPORT_ID } from "../../../globals/tableau-settings";
import { splitAtCapital } from "../../../components/tableau/consts";

export function getFakeTableauGlobalList() {
  return Object.keys(TABLEAU_GLOBAL_REPORT_ID).map((key) => {
    return {
      name: splitAtCapital(key),
      id: key,
      description: "",
    };
  });
}
