import React, { useEffect, useRef, useState } from "react";
import Meta from "../../components/meta";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSiteDials,
  fetchTablesIfNeeded,
  updateStatus,
  fetchDialInfo,
  fetchForceDialsRefreshAndGetDialInfo,
} from "../../store/actions/system-dashboard";
import { fetchLiveSystemActions, fetchShowEscalationBarIfNeeded } from "../../store/actions/escalation";
import { Dropdown, Modal, InlineError, Button, Icon, Tippy } from "@tscore/react-components";

import { IndicatorTable } from "./indicator-table";
import { MiniWheel } from "./mini-wheel";
import { EmptyIndicatorTable } from "./empty-indicator-table";
import { ModalEscalationActions } from "./modal-escalation-actions";
import { ModalEscalationActionsOverview } from "./modal-escalation-actions-overview";
import { ModalEscalationUsers } from "./modal-escalation-users";
// import { ModalIndicatorOverviewReport } from "./modal-indicator-overview-report";
import { PtlDropdownItem } from "./ptl-dropdown-item";
import { EscalationBar } from "./escalation-bar";
import { OverlayScrollerNative } from "../../components/overlay-scroller-native";
import { getSpecialScrollerMaxWidth } from "./get-special-scroller-max-width";
import { INTERNAL_URLS } from "../../globals/internal-urls";
import { POWERBI_REPORT_ID } from "../../globals/powerbi-settings";
import { removeLastSlashIfExists } from "../../helpers/remove-last-slash-if-exists";
// import { Modal, Icon, Button, Input, Tippy, Pagination, InlineError, DateTimePicker } from "@tscore/react-components";
// empty
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { IndicatorOverviewReportItem } from "./indicator-overview-report-item";
import { IntervalRefreshDials } from "./interval-refresh-dials";
// import {
//   generateBragStyles,
//   generateBragStylesOrClasses,
//   calculateIndicatorColourByThreshold,
// } from "../helpers/brag-helpers";
// import { decodeParams, updateParams } from "../helpers/params";
// import { NavLink } from "react-router-dom";

//? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
// import { ModalRefMethods } from "../../types/modal";

import { Wheel } from "../../components/wheel";

interface Params {
  id: string;
  siteId: "primary" | string;
  pretty?: string;
}

type WholeSystemResilienceProps = RouteComponentProps<Params>;

// const IndicatorReportModal = () => null;
// const SampleModal = () => null;

const LoadingEscalationBar = ({ isPlaceholder = false }: { isPlaceholder?: boolean }) => (
  <section className={classNames("escalation-bar", { isFetching: !isPlaceholder })}>
    <div className="loading-element" style={{ margin: 0, width: "100%" }} />
  </section>
);

const footerFix = `
  #footer > * {
    position:absolute;
    bottom:0;
    padding-bottom: 0.6rem;
    font-size: 1.1rem;
    right: 0;
  }
  
  [class*="theme-da"] .outerArcs > path[fill="#132E3D"], [class*="theme-da"] .data > path[fill="#132E3D"]  {
    fill:#171717;
  }
  `;

const sumObjectsByKey = (...objs: any[]) => {
  return objs.reduce((a, b) => {
    for (const k in b) {
      // eslint-disable-next-line
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
    }
    return a;
  }, {});
};

const MAIN_WHEEL_SETTINGS = {
  isResponsive: true,
  isStatic: false,
  showEscalation: true,
  size: 600,
  hideText: false,
};

export const WholeSystemResiliencePage: React.FC<WholeSystemResilienceProps> = ({ match, location }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const indicatorOverviewModalRef = useRef<ModalRefMethods>(undefined!);
  const [showWeights, setShowWeights] = useState(false);
  const id = parseInt(match.params.id, 10);
  let siteId = match.params.siteId === "primary" ? -1 : parseInt(match.params.siteId);
  const state = useSelector(
    (state: ApplicationState) => state.systemDashboardReducer[id] || state.systemDashboardReducer[-1]
  );
  const { dials, isFetchingDials, status, isSavingWeights, isForcingColour } = state;
  if (!isFetchingDials && siteId === -1 && dials && dials.length > 0) {
    siteId = dials[0].siteId;
  }
  const siteIndex = match.params.siteId === "primary" ? 0 : dials.findIndex((dial) => dial.siteId === siteId);
  const isEscalationEnabled = useSelector(
    (state: ApplicationState) => state.menuReducer.escalationEnabledDashboards[id]
  );
  const escalationState = useSelector(
    (state: ApplicationState) => state.escalationReducer[siteId] || state.escalationReducer[-1]
  );
  const meta = useSelector((state: ApplicationState) => state.menuReducer.meta);
  const { module, moduleUrlPrefix } = meta;
  const rba = useSelector(
    (state: ApplicationState) => state.menuReducer.rba[module][id] || state.menuReducer.rba["Resilience"][-1]
  );
  const { unitToLabelMap, liveActionList, fetchingEscalationUnits } = escalationState;
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("reset_status");
    dispatch(updateStatus(id, siteId, {}));
  }, [dispatch, id, siteId]);
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchSiteDials(id));
    }
    fetchInitial();
  }, [dispatch, id]);
  useEffect(() => {
    async function fetchInitialEscalation() {
      await dispatch(fetchLiveSystemActions(siteId, id));
    }
    if (isEscalationEnabled && siteId !== -1) {
      // console.log("get live system actions");
      fetchInitialEscalation();
    }
  }, [dispatch, isEscalationEnabled, siteId, id]);
  const statusBySiteId = status[siteId] || status["-1"];
  const statusList = Object.entries(statusBySiteId);
  const areActionsLoaded = Object.keys(liveActionList).length > 0 ? true : false;
  useEffect(() => {
    if (Object.keys(statusBySiteId).length > 0 && areActionsLoaded) {
      dispatch(fetchShowEscalationBarIfNeeded(siteId, statusBySiteId));
    }
  }, [dispatch, siteId, statusBySiteId, areActionsLoaded]);
  const showTables =
    !statusList || statusList.length === 0
      ? false
      : !statusList.every(([_key, v]) => v.active === undefined || v.active === false);
  const leftScroller = useRef<HTMLDivElement>(null!);

  const wheelCallback = (event: any): void => {
    dispatch(fetchTablesIfNeeded(id, siteId, event.status));
    // dispatch(fetchShowEscalationBarIfNeeded(siteId, event.status));
  };

  (window as any).wheelCallback = wheelCallback; //.bind(this);
  const statusEntries = statusList.slice(0).sort(([_keya, a], [_keyb, b]) => b.requestedOn! - a.requestedOn!);
  // if (isFetchingDials) {
  //   return <span>loading</span>;
  // }
  const tableWidthUpdatedOn = statusEntries.map(([_a, v]) => v.isFetched && v.active).join(",");
  return (
    <main id="system-dashboard">
      <Meta
        title={dials.length > 0 ? dials[siteIndex].siteName : "-"}
        breadcrumbs={t(["dashboards:breadcrumbs." + module, "dashboards:breadcrumbs.default"], { returnObjects: true })}
        isFetching={dials.length > 0 ? false : true}
      />
      <header className="content-header">
        <div>
          {module !== "NHS Region" && (
            <Dropdown
              position="right"
              trigger={
                <Button disabled={siteId === -1} className="dropdown-button" style={{ whiteSpace: "nowrap" }}>
                  {t("dashboards:Dashboard Actions")}
                </Button>
              }>
              {/* <SpecialTableauReport match={match} id={id} /> */}
              {isEscalationEnabled && (
                <Modal
                  noTriggerWrapper={true}
                  // style={{ overflow: "visible" }}
                  trigger={
                    <Dropdown.Item
                      className="escalation-accent-dropdown-item"
                      href="#!escalation-actions"
                      label={t("dashboards:Escalation Actions")}
                    />
                  }>
                  <ModalEscalationActions systemDashboardId={id} pdSiteId={siteId} />
                </Modal>
              )}

              {/* <Modal
              style={{ maxWidth: "560px" }}
              trigger={<Dropdown.Item href="#!indicator-report" label="Indicator Report" />}>
              <IndicatorReportModal />
            </Modal> */}
              {isEscalationEnabled && (
                <Dropdown.Item
                  className="escalation-accent-dropdown-item"
                  label={t("dashboards:Escalation Report")}
                  anchorComponent={({ onClick, ...props }: any) => {
                    return (
                      <NavLink
                        to={`${INTERNAL_URLS.powerbi}/${POWERBI_REPORT_ID.ESCALATION}/?dashboardId=${id}&source=${location.pathname}`}
                        {...props}
                      />
                    );
                  }}
                />
              )}
              <Dropdown.Item
                label={t("dashboards:Dashboard Report")}
                anchorComponent={({ onClick, ...props }: any) => {
                  return (
                    <NavLink
                      to={`${removeLastSlashIfExists(moduleUrlPrefix)}${INTERNAL_URLS.powerbi}/${
                        POWERBI_REPORT_ID.WINTER_REPORT
                      }/?siteId=${siteId}&source=${location.pathname}`}
                      {...props}
                    />
                  );
                }}
              />
              <IndicatorOverviewReportItem
                siteIndex={siteIndex}
                systemDashboardId={id}
                siteId={siteId}
                source={location.pathname}
              />
              {/* <Modal
                noTriggerWrapper={true}
                ref={indicatorOverviewModalRef}
                style={{ maxWidth: "560px" }}
                trigger={<Dropdown.Item href="#!indicator-report" label={t("dashboards:Indicator Report")} />}>
                <ModalIndicatorOverviewReport
                  siteIndex={siteIndex}
                  systemDashboardId={id}
                  afterSuccess={() => {
                    indicatorOverviewModalRef.current?.hide();
                  }}
                />
              </Modal> */}
              <Dropdown.Item isSpacer />
              <Dropdown.Item
                label={t("dashboards:Indicator Not Updated Report")}
                anchorComponent={({ onClick, ...props }: any) => {
                  return (
                    <NavLink
                      to={`${removeLastSlashIfExists(moduleUrlPrefix)}${INTERNAL_URLS.powerbi}/${
                        POWERBI_REPORT_ID.INDICATOR_NOT_UPDATED
                      }/?siteId=${siteId}&source=${location.pathname}`}
                      {...props}
                    />
                  );
                }}
              />
              <PtlDropdownItem ptlView={rba.PTL_VIEW} />
              {/* <Dropdown.Item label="Indicator Not Updated Report" /> */}
              {/* <Dropdown.Item
                label={t("dashboards:Indicator Overview Report")}
                anchorComponent={({ onClick, ...props }: any) => {
                  return (
                    <NavLink
                      to={`${removeLastSlashIfExists(moduleUrlPrefix)}${INTERNAL_URLS.powerbi}/${
                        POWERBI_REPORT_ID.INDICATOR_OVERVIEW_PAGINATED_REPORT
                      }/?rp:Parameter1=${id}&rp:Parameter2=${siteId}&source=${location.pathname}`}
                      {...props}
                    />
                  );
                }}
              /> */}
              {/* <Dropdown.Item label="Indicator Overview Report" /> */}
            </Dropdown>
          )}
        </div>
      </header>
      <div className="system-dashboard-layout">
        {siteId !== -1 && dials.length > 0 && <IntervalRefreshDials id={id} siteId={siteId} />}
        <div className="left">
          <div className="scroller" ref={leftScroller}>
            <div className="main-wheel">
              <div className="box" style={{ position: "relative" }}>
                <div className="refresh-dial__holder">
                  <Tippy content={t("dashboards:tooltipRefreshDialData")} placement="top">
                    <i
                      className={classNames("material-icons pointer table-refresh-icon", {
                        isRefreshing: isFetchingDials || isForcingColour[siteId] === true,
                      })}
                      onClick={() => dispatch(fetchForceDialsRefreshAndGetDialInfo(id, [siteId]))}>
                      cached
                    </i>
                  </Tippy>
                </div>
                {(() => {
                  if (
                    !isForcingColour[siteId] &&
                    (isFetchingDials || !dials[siteIndex] || !dials[siteIndex].isFetched)
                  ) {
                    return (
                      <div className="isFetching" style={{ height: "100%", padding: "15px" }}>
                        <div className="loading-element" style={{ height: "100%", borderRadius: "50%" }}>
                          Loading
                        </div>
                      </div>
                    );
                  }
                  if (dials[siteIndex] && dials[siteIndex].unitList && dials[siteIndex].unitList!.length > 0) {
                    return (
                      <Wheel
                        unitList={dials[siteIndex].unitList!}
                        wheelId={dials[siteIndex].siteId}
                        callback="wheelCallback"
                        {...MAIN_WHEEL_SETTINGS}
                      />
                    );
                  }
                  return (
                    <div
                      onClick={() => dispatch(fetchDialInfo(id, siteId))}
                      className="fake-svg-holder"
                      style={{ height: "100%", padding: "15px" }}>
                      <div
                        style={{
                          cursor: "pointer",
                          height: "100%",
                          borderRadius: "50%",
                          backgroundColor: "rgba(0,0,0,.07)",
                          padding: 0,
                          margin: "4px 0",
                        }}>
                        <Tippy placement="top" content={t("dashboards:errorTooltipDial")}>
                          <i className="material-icons svg-error-icon">refresh</i>
                        </Tippy>
                      </div>
                    </div>
                  );
                })()}
                {/* <SampleWheelSVG onClick={() => this.setState({ showTables: true })} /> */}
              </div>
            </div>
            <div className="mini-wheels">
              <div className="mini-wheels-holder">
                {dials.map((dial, index) => {
                  const isActive = index === siteIndex ? true : false;
                  return (
                    <div
                      key={index}
                      className={classNames("mini-wheel box p12", {
                        active: isActive,
                      })}>
                      <MiniWheel
                        dial={dial}
                        isActive={isActive}
                        locationPathname={location.pathname}
                        systemDashboardId={id}
                        module={module}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="right table-holder">
          <OverlayScrollerNative
            updateOn={tableWidthUpdatedOn}
            maxWidth={getSpecialScrollerMaxWidth}
            scrollerPosition="sticky">
            <div className="specialScroller">
              {isEscalationEnabled &&
                (Object.values(liveActionList).length > 0 ||
                  (escalationState.isFetching["GET_LIVE_SYSTEM_ACTIONS"] &&
                    Object.values(liveActionList).length === 0)) && (
                  <div className="escalation-bar-holder" key={id}>
                    <header>
                      <h3>{t("dashboards:Overall Progress Bar")}</h3>
                      <div>
                        <Modal
                          trigger={
                            <>
                              <Tippy content={t("dashboards:tooltipActionsOverview")} placement="bottom">
                                <span>
                                  <Icon className="pointer">leaderboard</Icon>
                                </span>
                              </Tippy>
                            </>
                          }>
                          <ModalEscalationActionsOverview
                            title={"ALL"}
                            dataset={Object.values(liveActionList).map((organisation) => organisation.dataset)}
                            datasetLabels={Object.values(liveActionList).map((organisation) => organisation.labelName)}
                          />
                        </Modal>
                      </div>
                    </header>
                    {Object.values(liveActionList).length === 0 ? (
                      <LoadingEscalationBar />
                    ) : (
                      <EscalationBar
                        dataset={sumObjectsByKey(...Object.values(liveActionList).map((item) => item.dataset))}
                        totalActions={Object.values(liveActionList).reduce(
                          (final: number, current) => current.totalActions + final,
                          0
                        )}
                      />
                    )}
                  </div>
                )}
              {!showTables ? (
                <>
                  {!isEscalationEnabled && (
                    <div className="escalation-bar-holder" style={{ visibility: "hidden" }}>
                      <header>
                        <h3>...</h3>
                        <div>
                          <i className="material-icons">bar_chart</i>
                        </div>
                      </header>
                      <LoadingEscalationBar isPlaceholder />
                    </div>
                  )}
                  <InlineError
                    className="middle"
                    style={{ height: "468px" }}
                    title={t("dashboards:noSegmentSelected")}
                    description={t("dashboards:noSegmentSelectedDescription")}
                  />
                </>
              ) : (
                <div>
                  {isEscalationEnabled &&
                    statusEntries.map(([key, value]: [string, any]) => {
                      const labelIds = unitToLabelMap[value.unitId] || [];
                      // console.log({ zz: unitToLabelMap[value.unitId] });
                      // escalationState.isFetching["GET_LIVE_SYSTEM_ACTIONS"]
                      // console.log(value)
                      if (value.active && fetchingEscalationUnits[value.unitId] === true) {
                        return (
                          <div className="escalation-bar-holder" key={key}>
                            <header style={{ opacity: 0 }}>
                              <h3>...</h3>
                            </header>
                            <LoadingEscalationBar />
                          </div>
                        );
                      }
                      if (!value.active || labelIds.length === 0) {
                        return null;
                      }
                      return labelIds.map((id: number) => (
                        <div className="escalation-bar-holder" key={id}>
                          <header>
                            <h3>
                              {t("dashboards:escalationBarTitlePrefix")} {liveActionList[id].labelName}
                            </h3>
                            <div>
                              <Modal
                                trigger={
                                  <>
                                    <Tippy content={t("dashboards:tooltipActionsOverview")} placement="bottom">
                                      <span>
                                        <Icon className="pointer">leaderboard</Icon>
                                      </span>
                                    </Tippy>
                                  </>
                                }>
                                <ModalEscalationActionsOverview
                                  title={liveActionList[id].labelName}
                                  dataset={[liveActionList[id].dataset]}
                                  datasetLabels={[liveActionList[id].labelName]}
                                />
                              </Modal>
                              <Modal
                                trigger={
                                  <>
                                    <Tippy content={t("dashboards:tooltipUsersAssigned")} placement="bottom">
                                      <span>
                                        <Icon className="pointer">supervisor_account</Icon>
                                      </span>
                                    </Tippy>
                                  </>
                                }>
                                <ModalEscalationUsers organisation={liveActionList[id]} pdSiteId={siteId} />
                              </Modal>
                            </div>
                          </header>
                          <EscalationBar
                            dataset={liveActionList[id].dataset}
                            totalActions={liveActionList[id].totalActions}
                          />
                        </div>
                      ));
                    })}
                  {statusEntries.map(([key, value]: [string, any]) => {
                    const isFetching = !value.isFetched;
                    if (value.active && isFetching) {
                      return <EmptyIndicatorTable title={key} key={key} />;
                    }
                    return null;
                  })}
                  {statusEntries.map(([key, value]: [string, any]) => {
                    const isFetching = !value.isFetched;
                    // console.log(value)
                    if (!value.active || isFetching) {
                      return null;
                    }
                    return (
                      <IndicatorTable
                        rba={rba}
                        showWeights={showWeights}
                        key={key}
                        title={key}
                        unitId={value.unitId}
                        data={value.data!}
                        systemDashboardId={id}
                        siteId={siteId}
                        isSavingWeights={isSavingWeights}
                        isFetchingTable={state.isFetching["GET_TABLES_" + value.unitId]}
                      />
                    );
                  })}
                  <aside className="table-footer ta-right">
                    {(rba.WEIGHT_EDIT || rba.WEIGHT_VIEW) && (
                      <a
                        href="#toggle-weights"
                        onClick={(e) => {
                          setShowWeights(!showWeights);
                          e.preventDefault();
                        }}>
                        {showWeights ? t("dashboards:Hide Weighting") : t("dashboards:Show Weighting")}
                      </a>
                    )}
                  </aside>
                </div>
              )}
            </div>
          </OverlayScrollerNative>
        </div>
      </div>
      {/* <style dangerouslySetInnerHTML={{__html: tableSetup}}/> */}
      <style dangerouslySetInnerHTML={{ __html: footerFix }} />
    </main>
  );
};
