import React, { Component } from "react";
import { Map } from "google-maps-react";
import GoogleApiWrapper from "../../../components/map/improved/GoogleApiComponent";
import MapTooltip from "../components/MapTooltip.component";
import LoadingContainer from "./LoadingContainer.component";
import FiltersComponent from "./Filters.component";
import ErrorsComponent from "./Errors.component";
// import { HospitalTooltip } from "./HospitalTooltip.component";
// import { HoverPopover as HospitalTooltip } from "../../../components/map/shared/hover-popover";
import { HospitalTooltip } from "./deprecatedHospitalTooltip.component";
import facilitiesList from "./lists/facilities";
// import trucksList from "./lists/trucks";
import countiesList from "./lists/counties";
import ccgsList from "./lists/ccgs";
//
// import { detectIE } from "../../../helpers/detect-ie";
import { generateFiltersListNew, howManySegmentsSelected } from "../helpers/map";
import debounce from "lodash/debounce";
// import throttle from "lodash/throttle";
import { mapSettings, initialCenter, BOUNDS_ZOOM_RELATIVE_TO_MAX } from "../globals";
import { INITIAL_OTHER_OPTIONS, HOVER_OUT_DELAY } from "../globals/settings";
// ? SERVICES
import axios from "axios";
import MainService from "../services/main.service";
// ? TYPES
import { TFunction } from "i18next";
import { AxiosError } from "axios";
import {
  ChildFeature,
  // GmMarker,
  ExpandedMarker,
  MapFilters,
  FacilityMarkerOptions,
  // TruckMarkerOptions,
  AreaPressureLevelCCG,
  // AreaPressureLevelOutput,
  // FilterIndicatorList,
  FilterPressureLevel,
  ParentFeature,
  FilterIndicator,
  MapFilter,
  FilterIndicatorListOutput,
  CoronaData,
  AreaPressureLevelInput,
} from "../types";

type PromiseError = AxiosError | string;
type Children = { [contractName: string]: ChildFeature[] };
type Parents = { [contractName: string]: any[] };

interface State {
  isFetching: {
    children: boolean;
    parent: boolean;
    facilities: boolean;
    mapFilters: boolean;
    childrenColours: boolean;
    // parentColours: boolean;
    initialLoad: boolean;
    initialBoundsReady: boolean;
    map: boolean;
  };
  bounds?: google.maps.LatLngBounds;
  isDragging: boolean;
  hasErrors: { [key: string]: string };
  mapFilters: MapFilters;
  mapOthers: MapFilters;
  currentFacilityHover: ExpandedMarker | null;
  lastFacilityHover: ExpandedMarker | null;
  facilities: FacilityMarkerOptions[];
  children: Children;
  parent: Parents;
  childrenColours: AreaPressureLevelCCG<FilterPressureLevel>[];
  // parentColours: AreaPressureLevel<FilterPressureLevel>[];
  currentChildHover: ChildFeature | null;
  lastChildHover: ChildFeature | null;
  currentParentHover: ParentFeature | null;
  // ieVersion: { type: "ie" | "edge" | null; version: number };
  zoom: number;
}

// const getXY = (MouseEvent: any, offsetX = "offsetX", offsetY = "offsetY"): { xy: number[] } => {
//   const x = MouseEvent.wa[offsetX];
//   const y = MouseEvent.wa[offsetY];
//   return { xy: [x, y] };
// };

const generateGeoResponse = (response: any[], contracts: string[], modifier = 0): Children | Parents => {
  const last = response.length - 1;
  return response.reduce((final: Children | Parents, currentResponse: any, i: number) => {
    if ((i + modifier) % 2 === 0 && i !== last) {
      const nameIndex = Object.keys(final).length;
      // console.log(nameIndex);
      final[contracts[nameIndex]] = currentResponse;
    }
    return final;
  }, {});
};

const generateBounds = (markers: FacilityMarkerOptions[], google: any): google.maps.LatLngBounds | undefined => {
  if (markers.length === 0) {
    return undefined;
  }
  const bounds = new google.maps.LatLngBounds();
  for (let i = 0; i < markers.length; i += 1) {
    bounds.extend(new google.maps.LatLng(markers[i].lat, markers[i].lng));
  }
  return bounds;
};

interface Props {
  // viewType: ViewType;
  contracts: string[];
  onFacilityClick?: (facility: FacilityMarkerOptions) => void;
  google: any;
  t: TFunction;
}

class MapComponent extends Component<Props, State> {
  state = {
    isFetching: {
      parent: true,
      children: true,
      facilities: true,
      // trucks: true,
      mapFilters: true,
      childrenColours: true,
      initialBoundsReady: true,
      // parentColours: true,
      initialLoad: true,
      map: true,
    },
    isDragging: false,
    hasErrors: {},
    mapFilters: {},
    mapOthers: INITIAL_OTHER_OPTIONS,
    currentFacilityHover: null,
    lastFacilityHover: null,
    lastChildHover: null,
    facilities: [],
    children: {},
    parent: {},
    childrenColours: [],
    // parentColours: [],
    currentChildHover: null,
    currentParentHover: null,
    // ieVersion: { type: null, version: 0 },
    bounds: undefined,
    zoom: mapSettings.zoom as number,
  };

  setErrors = (errors: { [key: string]: string }): void => {
    this.setState((prevState: State) => {
      return { hasErrors: { ...prevState.hasErrors, ...errors } };
    });
  };

  resetErrors = (): void => {
    this.setState({ hasErrors: {} });
  };

  useNull = (error: PromiseError, key: string): null => {
    if (error === "Request Cancelled") {
      return null;
    }
    let message = "Unknown";
    if (typeof error === "string") {
      message = error;
    } else if (error.request) {
      message = error.request.statusText || error.request.status;
    }
    this.setErrors({
      [key]: message + " (" + (new Date() as any).toISOString().split("T").pop().split("Z")[0] + ")",
    });
    return null;
  };

  getAreas = (): void => {
    const { contracts } = this.props;
    const contractPromises = contracts.reduce((final: Promise<any>[], contract: string) => {
      final.push(
        MainService.getChildrenGeoJson(contract).catch((error: PromiseError) =>
          this.useNull(error, "children: " + contract)
        )
      );
      final.push(
        MainService.getParentGeoJson(contract).catch((error: PromiseError) =>
          this.useNull(error, "parent: " + contract)
        )
      );
      return final;
    }, []);
    const promises = [
      ...contractPromises,
      MainService.getMapFilters().catch((error: PromiseError) => this.useNull(error, "mapFilters")),
    ];
    // console.log(promises);
    axios
      .all(promises as [Promise<ChildFeature[]>, Promise<any>, Promise<{ [key: string]: string }>, Promise<any>])
      .then((response) => {
        this.setState((prevState: State) => {
          const last = response.length - 1;
          const children = generateGeoResponse(response, contracts, 0);
          const parents = generateGeoResponse(response, contracts, 1);
          return {
            children: children,
            parent: parents,
            ...(response[last] && { mapFilters: generateFiltersListNew(response[last]) }),
            isFetching: {
              ...prevState.isFetching,
              children: false,
              parent: false,
              mapFilters: false,
              initialLoad: false,
            },
          };
        });
        // ? Got the both the boundaries, now get the colours:
        // if (childrenResponse && mapFiltersResponse) {
        //   // this.getAreaColours();
        //   console.log("getColours?");
        // } else {
        //   const errorMessage = "mapFilters + CCG areas dependency";
        //   this.setErrors({ CCGColours: errorMessage });
        //   this.setState((prevState: State) => {
        //     return { isFetching: { ...prevState.isFetching, childrenColours: false } };
        //   });
        // }
      });
  };

  // getInitialData = (): void => {
  //   const promises = [
  //     MainService.getFacilities().catch((error: PromiseError) => this.useNull(error, "facilities"))
  //     // MainService.getTrucks().catch((error: PromiseError) => this.useNull(error, "trucks"))
  //   ];
  //   axios.all(promises as [Promise<FacilityMarkerOptions[]>]).then(([facilitiesResponse]) => {
  //     this.setState((prevState: State) => {
  //       return {
  //         ...(facilitiesResponse && {
  //           facilities: facilitiesResponse.map((facility: any) => {
  //             if (facility.pressureLevel === null) {
  //               return { ...facility, ...{ pressureLevel: -1 } };
  //             }
  //             return facility;
  //           })
  //         }),
  //         // ...(trucksResponse && { trucks: trucksResponse }),
  //         isFetching: { ...prevState.isFetching, facilities: false }
  //       };
  //     });
  //   });
  // };

  getData = (filters?: FilterIndicatorListOutput): void => {
    // const childrenAndParentAreaPressureLevels = getAreaPressureLevels(this.state.children, this.state.mapFilters);
    const promises = [
      !filters
        ? MainService.getInitialData().catch((error: PromiseError) => this.useNull(error, "Initial Data"))
        : MainService.getData(filters).catch((error: PromiseError) => this.useNull(error, "Data")),
    ];
    axios.all(promises as [Promise<CoronaData>]).then(([dataResponse]) => {
      if (!dataResponse) {
        // probably cancelled
        // this.setState((prevState: State) => ({
        //   isFetching: { ...prevState.isFetching, childrenColours: false, facilities: false }
        // }));
      } else {
        this.setState((prevState: State) => {
          // TODO: transform to old response here
          const colours: AreaPressureLevelCCG<FilterPressureLevel>[] = dataResponse.ccgs.map(
            (item: AreaPressureLevelInput) => {
              return {
                id: item.ccgName,
                filters: item.pressureLevel,
                indicatorInfoDtos: item.indicatorInfoDtos,
              };
            }
          );
          const markers = dataResponse.markers.map((facility: any) => {
            if (facility.pressureLevel === null) {
              return { ...facility, ...{ pressureLevel: -1 } };
            }
            return facility;
          });
          const bounds = generateBounds(markers, this.props.google);
          return {
            ...(dataResponse && { childrenColours: colours, facilities: markers }),
            ...(dataResponse && !prevState.bounds && { bounds: bounds }),
            isFetching: { ...prevState.isFetching, childrenColours: false, facilities: false },
          };
        });
      }
    });
  };

  componentDidMount() {
    // this.setState({ ieVersion: detectIE(false) });
    this.getData();
    this.getAreas();
  }

  // ? MARKERS ==========================================================

  onFacilityOver = (_props: any, marker: ExpandedMarker, _mouseEvent: any) => {
    if (this.state.currentFacilityHover !== marker) {
      this.setState({ currentFacilityHover: marker, lastFacilityHover: marker });
    }
  };

  onFacilityOut = (_props: any, _marker: ExpandedMarker) => {
    if (this.state.currentFacilityHover) {
      this.setState({
        currentFacilityHover: null,
      });
    }
  };

  // ? ==================================================================

  // ? FILTERS ==========================================================
  onFilterChange = (key: string, shouldEnable: boolean, selected: FilterIndicator[] | null = []): void => {
    const ENABLE_ALL_BY_DEFAULT = true;
    if (selected === null) {
      selected = [];
    }
    let shouldUpdate = true;
    this.setState(
      (prevState: State) => {
        const selectedLength = (selected as FilterIndicator[]).length;
        if (
          prevState.mapFilters[key].indicators!.filter((item: FilterIndicator) => item.selected !== false).length ===
            0 &&
          selectedLength === 0
        ) {
          shouldUpdate = false;
        }
        if (howManySegmentsSelected(prevState.mapFilters) === 1 && selectedLength === 0) {
          shouldUpdate = false;
        }
        const indicators = prevState.mapFilters[key].indicators!.map((current: FilterIndicator) => {
          const isEnabled = prevState.mapFilters[key].enabled;
          const isSelected =
            isEnabled === false
              ? ENABLE_ALL_BY_DEFAULT
              : (selected as FilterIndicator[]).find(
                  (ind: FilterIndicator) => ind.indicatorGroupId === current.indicatorGroupId
                )
              ? true
              : false;
          return {
            ...current,
            selected: isSelected,
          };
        });
        const updatedFilter = {
          ...prevState.mapFilters[key],
          enabled: shouldEnable,
          indicators: indicators,
        };
        return {
          mapFilters: { ...prevState.mapFilters, [key]: updatedFilter },
          isFetching: { ...prevState.isFetching, childrenColours: shouldUpdate, facilities: shouldUpdate },
        };
      },
      () => {
        // console.log(this.state.mapFilters);
        // TODO: don't update when new one is selected with 0 indicators
        if (shouldUpdate) {
          const filters = Object.entries(this.state.mapFilters as MapFilters).reduce(
            (final: FilterIndicatorListOutput, [key, filter]: [string, MapFilter]) => {
              if (filter.enabled === true) {
                final[key] = filter
                  .indicators!.filter((indicator: FilterIndicator) => indicator.selected === true)
                  .map((indicator: FilterIndicator) => ({
                    indicatorGroupId: indicator.indicatorGroupId,
                    weight: indicator.weight,
                  }));
              }
              // else {
              //   final[key] = [];
              // }
              return final;
            },
            {}
          );
          this.getData(filters);
        }
      }
    );
  };
  onOtherChange = (key: string): void => {
    this.setState((prevState: State) => {
      const updatedFilter = {
        ...prevState.mapOthers[key],
        enabled: !prevState.mapOthers[key].enabled,
      };
      return {
        mapOthers: { ...prevState.mapOthers, [key]: updatedFilter },
      };
    });
  };

  // ? ==================================================================

  // ? POLYGONS ==========================================================

  onPolygonMouseMove = (e: any, feature: any, MouseEvent: any, onMoveFn: Function | null): void => {
    const currentHover = onMoveFn ? onMoveFn.call(this, feature) : {};
    this.setState({ ...currentHover });
  };

  onParentMove = (feature: any): any => {
    if (!this.state.currentParentHover || this.state.currentParentHover !== feature) {
      return { currentParentHover: feature };
    }
    return {};
  };

  onParentMouseOut = (e: any): void => {
    if (this.state.currentParentHover && e.id === (this.state.currentParentHover as ParentFeature).groupname) {
      this.setState({ currentParentHover: null });
    }
  };

  debouncedParentHoverOut = debounce(this.onParentMouseOut, HOVER_OUT_DELAY);

  onChildMove = (feature: ChildFeature): any => {
    if (!this.state.currentParentHover || this.state.currentParentHover !== feature) {
      const shouldClearLastFacilityHover = this.state.currentFacilityHover ? {} : { lastFacilityHover: null };
      return {
        currentChildHover: feature,
        lastChildHover: feature,
        ...shouldClearLastFacilityHover,
      };
    }
    return {};
  };

  onChildMouseOut = (e: any): void => {
    if (this.state.currentChildHover && (this.state.currentChildHover as any).properties.ccgCd === e.id) {
      this.setState({ currentChildHover: null });
    }
  };

  debouncedChildHoverOut = debounce(this.onChildMouseOut, HOVER_OUT_DELAY);

  // ? ==================================================================

  onMapMouseOut = (): void => {
    // ? Clear all hovers, just in case something bugged out (when hovering outside of the map)
    this.clearHoverStates();
  };

  onMapDragStart = (): void => {
    this.setState({ isDragging: true }, () => {
      this.clearHoverStates();
    });
  };

  onMapDragEnd = (): void => {
    this.setState({ isDragging: false });
  };

  clearHoverStates = (): void => {
    this.setState({
      currentChildHover: null,
      currentParentHover: null,
      currentFacilityHover: null,
    });
  };

  render() {
    const { google, onFacilityClick } = this.props;
    const { isFetching, currentChildHover, currentParentHover, currentFacilityHover } = this.state;
    // console.log(this.state.children, this.state.parent);
    const facilities =
      this.state.mapOthers.facilities.enabled &&
      facilitiesList({
        facilities: this.state.facilities,
        onMouseover: this.onFacilityOver,
        onMouseout: this.onFacilityOut,
        currentFacilityHover: currentFacilityHover,
        // ieVersion: this.state.ieVersion,
        onFacilityClick: onFacilityClick,
      });
    const counties =
      !isFetching.initialLoad &&
      Object.keys(this.state.parent).map((key: string) =>
        countiesList({
          parent: (this.state.parent as any)[key],
          // parentColours: this.state.parentColours,
          setErrors: this.setErrors,
          currentParentHover: currentParentHover,
          onPolygonMouseMove: this.onPolygonMouseMove,
          onMouseout: this.debouncedParentHoverOut,
          onMousemove: this.onParentMove,
        })
      );
    const ccgs =
      (!isFetching.initialLoad || !isFetching.childrenColours) &&
      Object.keys(this.state.children).map((key: string) =>
        ccgsList({
          children: (this.state.children as any)[key],
          childrenColours: this.state.childrenColours,
          // parentColours: this.state.parentColours,
          currentChildHover: currentChildHover,
          onPolygonMouseMove: this.onPolygonMouseMove,
          onMouseout: this.debouncedChildHoverOut,
          onMousemove: this.onChildMove,
        })
      );
    return (
      <div className="google-map shadowed" style={{ height: "100%" }}>
        <Map
          containerStyle={{ position: "relative" }}
          style={{ width: "100%", height: "100%", position: "relative" }}
          google={google}
          // onMouseout={this.onMapMouseOut}
          // onMousemove={this.onMapMouseMove}
          onDragstart={this.onMapDragStart}
          onDragend={this.onMapDragEnd}
          onReady={() =>
            this.setState((prevState: State) => ({
              isFetching: {
                ...prevState.isFetching,
                map: false,
              },
            }))
          }
          onZoom_changed={() => {
            // setTimeout(() => {
            if (isFetching.initialBoundsReady) {
              this.setState((prevState: State) => {
                return {
                  zoom: (mapSettings.maxZoom as number) + BOUNDS_ZOOM_RELATIVE_TO_MAX,
                  isFetching: { ...prevState.isFetching, initialBoundsReady: false },
                };
              });
            }
            // }, 0);
          }}
          // onIdle={() => this.setState({ zoom: this.state.zoom + BOUNDS_ZOOM_RELATIVE_TO_MAX })}
          initialCenter={initialCenter}
          bounds={!isFetching.map ? this.state.bounds : undefined}
          {...mapSettings}
          zoom={this.state.zoom}>
          <ErrorsComponent errors={this.state.hasErrors} onClose={this.resetErrors} />
          <FiltersComponent
            isFetchingMapFilters={isFetching.mapFilters}
            isFetchingColours={isFetching.childrenColours}
            filters={this.state.mapFilters}
            onFilterChange={this.onFilterChange}
            others={this.state.mapOthers}
            onOtherChange={this.onOtherChange}
            errors={this.state.hasErrors}
          />
          <HospitalTooltip
            currentFacilityHover={this.state.lastFacilityHover}
            currentCCGHover={this.state.lastChildHover}
            facilities={this.state.facilities}
            childrenColours={this.state.childrenColours}
            onFacilityClose={(_e?: any) =>
              this.setState({
                lastFacilityHover: null,
                lastChildHover: null,
              })
            }
          />
          {ccgs}
          {counties}
          <MapTooltip
            currentFacilityHover={currentFacilityHover}
            currentChildHover={currentChildHover}
            currentParentHover={currentParentHover}
            filters={this.state.mapFilters}
            // parentColours={this.state.parentColours}
            childrenColours={this.state.childrenColours}
            isDragging={this.state.isDragging}
          />
          {facilities}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_GOOGLE_MAP_KEY,
  LoadingContainer: LoadingContainer,
  libraries: [],
})(MapComponent);
