import { loadStorage, saveStorage } from "../../helpers/localstorage";

import { getInitials } from "../reducers/credentials/get-initials";
import { getMenu } from "../actions/auth/menu";
import { SHOULD_CACHE_MENU } from "../../globals/settings";
// ?TYPES:
import { CredentialsState } from "../types/credentials-state";

const CREDENTIALS_NAME = "credentialsReducer";
const MENU_NAME = "menuReducer";

const GET_STORE: any = {
  READ_STORAGE: CREDENTIALS_NAME,
  API_START: {
    GET_MENU: SHOULD_CACHE_MENU ? MENU_NAME : undefined,
  },
};

const SAVE_STORE: any = {
  LOG_IN: CREDENTIALS_NAME,
  GET_MENU: SHOULD_CACHE_MENU ? MENU_NAME : undefined,
};

const SAVE_STORE_CURRENT_STATE: any = {
  UPDATE_PROFILE: CREDENTIALS_NAME,
  REFRESH_TOKEN: CREDENTIALS_NAME,
};

// const DELETE_STORE: any = {
//   LOG_OUT: [CREDENTIALS_NAME, SHOULD_CACHE_MENU ? MENU_NAME : null].filter(Boolean),
// };

// const fetchFromServer: any = {};

const cacheMiddleware =
  (store: any) =>
  (next: any) =>
  (action: { type: string; [key: string]: any } = { type: "" }): any => {
    const { type, ...payload } = action;
    if (GET_STORE[type]) {
      const label = payload.payload;
      if (type === "READ_STORAGE") {
        // const current = store.getState()[GET_STORE[type]];
        const data = (loadStorage(GET_STORE[type]) || { data: undefined }).data;
        if (data) {
          return next({ type: "LOG_IN", data });
        }
      }
      if (type === "API_START") {
        if (GET_STORE.API_START[label]) {
          const data = (loadStorage(GET_STORE.API_START[label]) || { data: undefined }).data;
          if (data) {
            return next(getMenu(data, true));
            // console.log("use cached", data);
          }
        }
      }
    }
    if (SAVE_STORE_CURRENT_STATE[type]) {
      if (type === "UPDATE_PROFILE") {
        const data: CredentialsState = store.getState()[SAVE_STORE_CURRENT_STATE[type]];
        const fullname = payload.data.firstname + " " + payload.data.surname;
        const newData = {
          ...data,
          app_user_info: {
            ...data.app_user_info,
            initials: getInitials(fullname),
            username: action.data.username,
            fullname: fullname,
          },
        };
        // console.log(newData);
        saveStorage(SAVE_STORE_CURRENT_STATE[type], { data: newData });
        next({ type: "LOG_IN", data: newData });
        return next(action);
      }
      if (type === "REFRESH_TOKEN") {
        saveStorage(SAVE_STORE_CURRENT_STATE[type], {
          data: {
            ...store.getState()[SAVE_STORE_CURRENT_STATE[type]],
            app_user_info: payload.data.app_user_info,
            access_token: payload.data.access_token,
            refresh_token: payload.data.refresh_token,
          },
          pathname: "/",
        });
        return next(action);
      }
      // saveStorage(SAVE_STORE_CURRENT_STATE[type], { data: store.getState()[SAVE_STORE_CURRENT_STATE[type]] });
      return next(action);
    }
    if (SAVE_STORE[type]) {
      saveStorage(SAVE_STORE[type], payload);
    }
    // if (DELETE_STORE[type]) {
    //   deleteStorage(DELETE_STORE[type]);
    // }
    return next(action);
  };

export default cacheMiddleware;
