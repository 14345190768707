import API, {
  // eslint-disable-next-line
  CancelToken
} from "../API";
import { generateParams, DEFAULT_CONTENT_TYPE_HEADERS } from "./config";
import { SERVICE_URLS } from "../../../globals/service-urls";
// ? TYPES
import {
  ChildFeature,
  // MapFilters,
  // FacilityMarkerOptions,
  // DefaultMarkerOptions,
  // TruckMarkerOptions,
  // AreaPressureLevel,
  FilterIndicatorListOutput,
  // FilterPressureLevel,
  // AreaPressureLevelOutput,
  CoronaData,
} from "../types";

import { ImportedMapFilter } from "../types/filters.type";

const GJ_API_VER = "api/v2";
const GJ_PARTIAL = `/slamdunk-geojson/${GJ_API_VER}`;
// const URGENT_RESPONSE_PARTIAL = "/ShrewdResilience/urgentResponse";
const CORONA_PARTIAL = `${SERVICE_URLS["system-dashboard-service"]}1/covid`;

// const getAllCCGNames = (): AxiosPromise<string[]> => {
//   return axios({
//     baseURL: process.env.REACT_APP_API_URL,
//     url: `${GJ_PARTIAL}/allccgnames`,
//     method: "GET"
//   });
// };

// ? children = white boundaries
const getChildrenGeoJson = (featureName: string): Promise<ChildFeature[]> => {
  const params = {
    feature_name: encodeURIComponent(featureName),
  };
  return API({
    // baseURL: process.env.REACT_APP_SLAMDUNK_URL,
    url: `${GJ_PARTIAL}/feature/children${generateParams(params)}`,
    method: "GET",
  });
};

// ? parent = black boundaries
const getParentGeoJson = (featureName: string): Promise<any> => {
  const params = {
    feature_name: encodeURIComponent(featureName),
  };
  return API({
    // baseURL: process.env.REACT_APP_SLAMDUNK_URL,
    url: `${GJ_PARTIAL}/feature/parent${generateParams(params)}`,
    method: "GET",
  });
};

// const getFacilities = (): Promise<FacilityMarkerOptions[]> => {
//   return API({
//     url: `${CORONA_PARTIAL}/markers`,
//     method: "GET"
//   });
// };

const getMapFilters = (): Promise<ImportedMapFilter> => {
  return API({
    url: `${CORONA_PARTIAL}/filters`,
    headers: DEFAULT_CONTENT_TYPE_HEADERS(),
    method: "GET",
  });
};

// const getInitialChildrenColours = (): Promise<AreaPressureLevelOutput> => {
//   return API({
//     url: `${CORONA_PARTIAL}/calculateColor`,
//     method: "GET"
//   });
// };

// const getChildrenColours = (filters: FilterIndicatorListOutput): Promise<AreaPressureLevelOutput> => {
//   return API({
//     url: `${CORONA_PARTIAL}/calculateColor`,
//     method: "POST",
//     data: filters,
//     headers: DEFAULT_CONTENT_TYPE_HEADERS
//   });
// };

let getDataCancel: Function | undefined = undefined;

const getInitialData = (): Promise<CoronaData> => {
  return API({
    url: `${CORONA_PARTIAL}/data`,
    method: "GET",
    headers: DEFAULT_CONTENT_TYPE_HEADERS(),
    cancelToken: new CancelToken(function (cancel) {
      getDataCancel = cancel;
    }),
  });
};

const getData = (filters: FilterIndicatorListOutput): Promise<CoronaData> => {
  typeof getDataCancel === "function" && getDataCancel();
  return API({
    url: `${CORONA_PARTIAL}/data`,
    method: "POST",
    data: filters,
    headers: DEFAULT_CONTENT_TYPE_HEADERS(),
    cancelToken: new CancelToken(function (cancel) {
      getDataCancel = cancel;
    }),
  });
};

const MainService = {
  // getAllCCGNames,
  getChildrenGeoJson,
  getParentGeoJson,
  // getFacilities,
  getMapFilters,
  // getInitialChildrenColours,
  // getChildrenColours,
  getData,
  getInitialData,
  // getTrucks,
  // getAreaColours
};

export default MainService;
