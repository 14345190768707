import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { FACILITY_ICON_SIZE } from "../../globals/settings";
interface Props {
  backgroundColour: string;
  text: string;
  colour: string;
  // ieVersion?: { type: "ie" | "edge" | null; version: number };
}

const FONT_SIZE = 9;
const STROKE_WIDTH = FACILITY_ICON_SIZE.STROKE_WIDTH;
const textStyles = {
  fontFamily: "sans-serif",
  fontSize: `${FONT_SIZE}px`,
  textAnchor: "middle" as const,
  dominantBaseline: "middle" as const,
};

const topBoxPath = (params: { width: number; height: number; offset: number; radius: number }): string => {
  const top = -params.offset - params.height;
  return `M 0,0
L ${-params.offset},${-params.offset} 
H ${-params.width / 2 + params.radius} 
Q ${-params.width / 2},${-params.offset}  
${-params.width / 2},${-params.offset - params.radius} 
V ${top + params.radius} 
Q ${-params.width / 2},${top}  
${-params.width / 2 + params.radius},${top}
H ${params.width / 2 - params.radius}
Q ${params.width / 2},${top}  
${params.width / 2},${top + params.radius} 
V ${-params.offset - params.radius}
Q ${params.width / 2},${-params.offset}  
${params.width / 2 - params.radius},${-params.offset}
H ${params.offset} z`;
};

export const HospitalIcon = ({
  backgroundColour = "#232A3F",
  text = "?",
  colour = "white",
}: // ieVersion = { type: null, version: 0 },
Props): JSX.Element => {
  const width = FACILITY_ICON_SIZE.WIDTH;
  const offset = FACILITY_ICON_SIZE.BOX_OFFSET;
  const boxHeight = FACILITY_ICON_SIZE.BOX_HEIGHT;
  const height = offset + boxHeight;
  const d = topBoxPath({
    width: width,
    height: boxHeight,
    offset: offset,
    radius: 4,
  });
  const additionalTextYOffset = 0;
  // const additionalTextYOffset = ieVersion.version >= 1 ? 3 : 0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width + STROKE_WIDTH * 2} ${height + STROKE_WIDTH * 2}`}
      width={width + STROKE_WIDTH * 2}
      height={height + STROKE_WIDTH * 2}>
      <g>
        <path
          transform={`translate(${width / 2 + STROKE_WIDTH},${height + STROKE_WIDTH})`}
          d={d}
          fill={backgroundColour}
          stroke={colour}
          strokeWidth={STROKE_WIDTH}></path>
        <text
          x={width / 2 + STROKE_WIDTH}
          y={FONT_SIZE + STROKE_WIDTH + 1 + additionalTextYOffset}
          style={textStyles}
          fill={colour}>
          {text}
        </text>
      </g>
    </svg>
  );
};

export const hospitalIconAsDataUri = (props: Props): string => {
  //   encodeURIComponent(
  //     renderToStaticMarkup(<HospitalIcon {...props} />)
  //       .replace(/"/g, "'")
  //       .replace(/(\r\n|\n|\r)/gm, "")
  //   )
  const svgString = encodeURIComponent(renderToStaticMarkup(<HospitalIcon {...props} />));
  return `data:image/svg+xml,${svgString}`;
};
