/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 * @edited Artur
 */

import axios from "axios";

export const CancelToken = axios.CancelToken;
(window as any).cancel = null;

/**
 * Create an Axios Client with defaults
 */
const client: any = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: 90000,
});

const handleError = (error: any): void => {
  console.error("Request Failed:", error.config);

  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    // console.error("Status:", error.response.status);
    // console.error("Data:", error.response.data);
    // console.error("Headers:", error.response.headers);
  } else {
    // Something else happened while setting up the request
    // triggered the error
    // console.error("Error Message:", error.message);
  }
};

/**
 * Request Wrapper with default success/error actions
 */
const API = function (options: any) {
  const onSuccess = function (response: any) {
    // console.debug('Request Successful!', response);
    return response.data;
  };

  const onError = function (error: any) {
    if (axios.isCancel(error)) {
      error.message = "Request Cancelled";
    } else {
      handleError(error);
    }
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default API;
