import React, { lazy, Suspense } from "react";
import { Redirect } from "react-router-dom";
import { INTERNAL_URLS } from "./globals/internal-urls";
import { nhsrRoutes } from "./modules/nhsr/routes";
import { electiveRoutes } from "./modules/elective/routes";
import { vantageRoutes } from "./modules/vantage/routes";
// PAGES:
import { HomePage } from "./pages/home";
import { LoginPage, ForgotPage, ResetPage, ActivatePage, MfaSetupPage, MfaEnforcePage } from "./pages/login";
import { ManualUpdatesPage } from "./pages/manual-updates";
import { WholeSystemResiliencePage } from "./pages/whole-system-resilience";
import { DocumentsPage } from "./pages/documents";
import { MyAccountPage } from "./pages/my-account";
import { ErrorPage } from "./pages/error";
import { PowerBI } from "./components/power-bi";
import { TableauPage } from "./components/tableau";
import { ReportListPage } from "./pages/reports";
import Report from "./pages/reports/report";
// import { PredictionPage } from "./pages/prediction";
import { LoginUnauthorised } from "./pages/login/login-unauthorised.tsx";
import { CovidPage } from "./pages/covid";
// import HelpPage from "./pages/help";
// import helpContent from "./globals/help-content.json";

// ? TYPES:
// import { RouteProps, RouteComponentProps } from "react-router";
import { SpecialRouteProps } from "./types/special-route-props";
import { LazyComponent } from "./types/lazy-component";

const LazyHelpPage = lazy(
  async () => import(/* webpackChunkName: "help-page" */ "./pages/help/index.tsx") as unknown as LazyComponent
);

// const HelpPage = () => <div>help</div>;
// const Test = () => <div>,,,</div>;

export const routes: SpecialRouteProps[] = [
  {
    exact: true,
    path: INTERNAL_URLS.home,
    component: HomePage,
  },
  {
    path: [
      `/tableau/:tableauId`,
      `/:moduleName/tableau/:tableauId`,
      `${INTERNAL_URLS.systemDashboardList}/:id/:siteId/tableau/:tableauId`,
    ],
    component: TableauPage,
  },
  {
    path: [INTERNAL_URLS.login, INTERNAL_URLS.setupMfa, INTERNAL_URLS.enforceMfa],
    children: <Redirect to={INTERNAL_URLS.home} />,
  },
  {
    path: `${INTERNAL_URLS.manualUpdateOrganisationList}/:id/:pretty?`,
    component: ManualUpdatesPage,
  },
  {
    path: `${INTERNAL_URLS.systemDashboardList}/:id/:siteId/:pretty?`,
    component: WholeSystemResiliencePage,
  },
  {
    path: `${INTERNAL_URLS.documentOrganisationList}/:id/:pretty?`,
    component: DocumentsPage,
  },
  {
    path: [`${INTERNAL_URLS.myAccount}/:type`, `/:moduleName${INTERNAL_URLS.myAccount}/:type`],
    component: MyAccountPage,
  },
  {
    path: [INTERNAL_URLS.help, "/:moduleName" + INTERNAL_URLS.help],
    render: (props) => (
      <Suspense fallback={<div />}>
        <LazyHelpPage {...props} />
      </Suspense>
    ),
  },
  {
    path: [`${INTERNAL_URLS.powerbi}/:reportId/:pretty?`, `/:moduleName${INTERNAL_URLS.powerbi}/:reportId/:pretty?`],
    component: PowerBI,
  },
  // {
  //   path: "/prediction/:idDashboard/:pretty?",
  //   component: PredictionPage,
  // },
  // {
  //   path: `${INTERNAL_URLS.winterReportOrganisationList}/:id/winterReport/:pretty?`,
  //   component: ReportListPage,
  // },
  {
    path: `${INTERNAL_URLS.otherReportOrganisationList}/:id/:type/:pretty?`,
    component: ReportListPage,
  },
  {
    path: [
      "/report/:reportType/:id/:renderType/:friendly?",
      "/:moduleName/report/:reportType/:id/:renderType/:friendly?",
    ],
    component: Report,
  },
  {
    path: "/covid",
    component: CovidPage,
  },
  ...nhsrRoutes,
  ...electiveRoutes,
  ...vantageRoutes,
  {
    path: [`${INTERNAL_URLS.resetPassword}/:username?/:key?`, `${INTERNAL_URLS.activateAccount}/:username?/:key?`],
    component: LoginUnauthorised,
  },
  {
    path: ["/error/:code", "/:moduleName/error/:code"],
    component: ErrorPage,
  },
  {
    path: "*",
    component: ErrorPage,
  },
];

export const unauthorizedRoutes: SpecialRouteProps[] = [
  {
    path: INTERNAL_URLS.login,
    ComponentWithExtraProps: LoginPage,
    // render: (props) => <LoginPage {...props} />,
  },
  {
    path: INTERNAL_URLS.forgotPassword,
    ComponentWithExtraProps: ForgotPage,
    // render: (props) => <ForgotPage {...props} />,
  },
  {
    path: `${INTERNAL_URLS.resetPassword}/:username?/:key?`,
    ComponentWithExtraProps: ResetPage,
    // render: (props) => <ResetPage {...props} />,
  },
  {
    path: `${INTERNAL_URLS.activateAccount}/:username?/:key?`,
    ComponentWithExtraProps: ActivatePage,
    // render: (props) => <ActivatePage {...props} />,
  },
  {
    path: `${INTERNAL_URLS.setupMfa}`,
    ComponentWithExtraProps: MfaSetupPage,
  },
  {
    path: `${INTERNAL_URLS.enforceMfa}`,
    ComponentWithExtraProps: MfaEnforcePage,
  },
  {
    path: "*",
    ComponentWithExtraProps: LoginPage,
    // render: (props) => <LoginPage {...props} />,
  },
  // {
  //   path: "*",
  //   children: <Redirect to="/login" />,
  // },
];
