import React, { useEffect, createContext, useContext, useState } from "react";
import { loadStorage, saveStorage } from "../helpers/localstorage";

type ConfigProviderProps = {
  children: React.ReactNode;
};

type ConfigProviderState = {
  sidebarCollapsed: boolean;
  sidebarCollapsedFinished: boolean;
  setSidebarCollapsed: (isCollapsed: boolean) => void;
  toggleSidebarCollapsed: (e?: any) => void;
};

const initialState = {
  sidebarCollapsed: false,
  sidebarCollapsedFinished: false,
  setSidebarCollapsed: () => null,
  toggleSidebarCollapsed: () => null,
};

const ConfigProviderContext = createContext<ConfigProviderState>(initialState);

export function ConfigProvider({ children, ...props }: ConfigProviderProps) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    () => (loadStorage("shrewd-sidebar-collapsed") as boolean) || false
  );
  const [sidebarCollapsedFinished, setSidebarCollapsedFinished] = useState(
    () => (loadStorage("shrewd-sidebar-collapsed") as boolean) || false
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSidebarCollapsedFinished(sidebarCollapsed);
    }, 820);
    return () => clearTimeout(timeoutId);
  }, [setSidebarCollapsedFinished, sidebarCollapsed]);

  const value = {
    sidebarCollapsed,
    sidebarCollapsedFinished,
    setSidebarCollapsed: (isCollapsed: boolean) => {
      saveStorage("shrewd-sidebar-collapsed", isCollapsed);
      setSidebarCollapsed(isCollapsed);
    },
    toggleSidebarCollapsed: (e?: any) => {
      if (e) {
        e.preventDefault();
      }
      setSidebarCollapsed((b) => {
        saveStorage("shrewd-sidebar-collapsed", !b);
        return !b;
      });
    },
  };

  return (
    <ConfigProviderContext.Provider {...props} value={value}>
      {children}
    </ConfigProviderContext.Provider>
  );
}

export const useConfig = () => {
  const context = useContext(ConfigProviderContext);

  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }

  return context;
};
