import React from "react";
import { Polygon } from "../../../../components/map/improved/Polygon";
import { mapCoordinatesToPolygon, getPolygonOpacity } from "../../helpers/map";
import { BRAG_COLOURS, BRAG_PRESSURE_LEVEL_MAPPING } from "../../globals";
import { CHILD_POLYGON_OPACITY, defaultChildPolygonOptions } from "../../globals/settings";
// ? TYPES:
import { ChildFeature, AreaPressureLevel, FilterPressureLevel } from "../../types";
interface Props {
  children: ChildFeature[];
  childrenColours: AreaPressureLevel<FilterPressureLevel>[];
  // parentColours: AreaPressureLevel<FilterPressureLevel>[];
  currentChildHover: ChildFeature | null;
  // viewType: ViewType;
  onMouseout: Function;
  onPolygonMouseMove: Function;
  onMousemove: Function;
}

const getAreaFilters = (colours: AreaPressureLevel<FilterPressureLevel>[], id: string) =>
  colours.find((item: AreaPressureLevel<FilterPressureLevel>) => item.id === id);

const ccgsList = ({
  children,
  childrenColours,
  // parentColours,
  currentChildHover,
  // viewType,
  onMouseout,
  onPolygonMouseMove,
  onMousemove,
}: Props): JSX.Element[] | null => {
  if (!children) {
    return null;
  }
  return children.map((feature: ChildFeature) => {
    const id = feature.properties.ccgCd;
    const coordinates = mapCoordinatesToPolygon(feature.geometry.coordinates);
    const fillOpacity = getPolygonOpacity(currentChildHover, feature, CHILD_POLYGON_OPACITY);
    const areaFilters = getAreaFilters(childrenColours, feature.properties.ccgName);

    const pressureLevel = areaFilters ? (areaFilters as AreaPressureLevel<FilterPressureLevel>).filters.total : 0;
    const fillColor = BRAG_COLOURS[BRAG_PRESSURE_LEVEL_MAPPING[pressureLevel]].colour;
    return (
      <Polygon
        key={id}
        id={id}
        name={feature.properties.ccgName}
        zIndex={2}
        paths={coordinates}
        {...defaultChildPolygonOptions}
        fillColor={fillColor}
        fillOpacity={fillOpacity}
        // onMouseover={(e: any, b: any, MouseEvent: any) => this.onChildMouseOver(e, feature)}
        onMousemove={(e: any, _b: any, mouseEvent: any) => onPolygonMouseMove(e, feature, mouseEvent, onMousemove)}
        onMouseout={(e: any, _b: any, _mouseEvent: any) => onMouseout(e)}
        // onClick={(e: any) => this.handleChildClick(e)}
      />
    );
  });
};

export default ccgsList;
