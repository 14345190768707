import React, { useState, Fragment } from "react";
// import { IndicatorSelect } from "./IndicatorSelect.component";
import { FilterSelect as IndicatorSelect } from "../../../components/map/shared/filter-select";

// import { useClickOutside } from "../hooks/use-click-outside";
import { howManySegmentsSelected } from "../helpers/map";
// import { LANG } from "../globals/lang";
import { useTimeout } from "../hooks/use-timeout";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { TFunction } from "i18next";
import { MapFilters, FilterIndicator } from "../types";
interface Props {
  filters: MapFilters;
  onFilterChange: Function;
  isFetchingMapFilters: boolean;
  isFetchingColours: boolean;
  others: MapFilters;
  onOtherChange: Function;
  errors: { [key: string]: string };
  t: TFunction;
}

type FilterErrors = { [key: string]: string[] };

const LOADER_STYLE = { height: "1.878rem", marginBottom: "8px" };

const FiltersComponent = ({
  filters,
  onFilterChange,
  others,
  onOtherChange,
  isFetchingMapFilters,
  isFetchingColours,
  errors,
  t,
}: Props): JSX.Element => {
  const [filterErrors, setFilterErrors] = useState<FilterErrors>({});
  const clearFilterErrors = () => setFilterErrors({});
  const debouncedClearFilterErrors = useTimeout(clearFilterErrors, 3600);
  // const [menuOpen, setMenuOpen] = useState<string | null>(null);
  // const dropdownContainer = useRef<HTMLDivElement>(null!);
  const setAtLeasetOneSegmentError = (key: string): void => {
    setFilterErrors({ [key]: [t("atLeastOneSegment")] });
    debouncedClearFilterErrors();
  };
  const onIndicatorChange = (selected: any | FilterIndicator[] | null, action: any, key: string) => {
    onFilterChange(key, true, selected);
  };
  const onSegmentClick = (key: string, shouldEnable: boolean): void => {
    clearFilterErrors();
    if (shouldEnable === true) {
      // ! for radio
      const filterToRemove = Object.values(filters).find((value) => value.enabled === true);
      if (filterToRemove) {
        onFilterChange(filterToRemove.name, false, []);
      }
      //
      onFilterChange(
        key,
        shouldEnable,
        filters[key].indicators!.map((indicator: FilterIndicator) => ({
          ...indicator,
          selected: true,
        }))
      );
    } else {
      // ! if checkbox uncomment below
      // if (howManySegmentsSelected(filters) > 1) {
      //   onFilterChange(key, shouldEnable, []);
      // } else {
      //   setAtLeasetOneSegmentError(key);
      // }
    }
  };
  // const onClose = () => {
  //   setMenuOpen(null);
  // };
  // useClickOutside(dropdownContainer, onClose);
  // const onToggle = (e: any, key: string) => {
  //   // console.log(key, "??");
  //   e.stopPropagation();
  //   e.preventDefault();
  //   if (menuOpen === key) {
  //     onClose();
  //   } else {
  //     setMenuOpen(key);
  //   }
  // };

  const onMenuClose = (key: string, indicators: FilterIndicator[]): void => {
    const length = indicators.filter((indicator: FilterIndicator) => indicator.selected === true).length;
    if (howManySegmentsSelected(filters) > 1 && length === 0) {
      onSegmentClick(key, false);
    } else if (length === 0) {
      setAtLeasetOneSegmentError(key);
      onFilterChange(key, false, []);
      setTimeout(() => {
        // ! Temporary hack to reselect all
        onFilterChange(
          key,
          true,
          filters[key].indicators!.map((indicator: FilterIndicator) => ({
            ...indicator,
            selected: true,
          }))
        );
      }, 0);
    }
    // TODO: hide with 0 selected
  };

  // const onKeyDown = ({ keyCode }: React.KeyboardEvent<HTMLElement>) => keyCode === 27 && onClose();
  return (
    <aside className="map-overlay box filters">
      <header>
        <h3>
          <span>{t("Filter Options")}</span>
          <span>{isFetchingColours && <i className="l inline" />}</span>
        </h3>
      </header>
      <section>
        <h4>{t("Segments and Indicators")}</h4>
        {isFetchingMapFilters ? (
          <div className="isFetching">
            {[1, 2, 3, 4, 5, 6].map((item: number) => (
              <Fragment key={item}>
                <div className="loading-element" style={LOADER_STYLE} />
                {item === 6 && (
                  <div className="indicator-select select" style={{ backgroundImage: "none" }}>
                    <div className="loading-element" style={{ height: "28px" }} />
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        ) : (
          Object.keys(filters).map((key: string, index: number) => (
            <div key={filters[key].name}>
              <label className="radio mb8">
                <input
                  type="radio"
                  checked={filters[key].enabled}
                  onChange={() => onSegmentClick(key, !filters[key].enabled)}
                  tabIndex={(index + 1) * 2 - 1}
                />
                <span>{filters[key].name}</span>
              </label>
              {filters[key].enabled && (
                <div style={{ marginBottom: "0.8rem" }}>
                  <IndicatorSelect
                    optionValueName="name"
                    placeholder={t("numberSelected_zero")}
                    placeholderKey={t("numberSelected")}
                    placeholderKeyOne={t("numberSelected_1")}
                    // onFocus={(e: any) => onToggle(e, key)}
                    defaultValue={
                      filters[key].indicators!.filter(
                        (indicator: FilterIndicator) => indicator.selected === true
                      ) as any
                    }
                    onChange={(selected, action) => onIndicatorChange(selected, action, key)}
                    name={key}
                    options={filters[key].indicators}
                    // menuIsOpen={menuOpen === key}
                    onMenuClose={() => onMenuClose(key, filters[key].indicators!)}
                    // onKeyDown={onKeyDown}
                    // onFocus={(e: any) => onToggle(key)}
                    tabIndex={(index + 1) * 2 + ""}
                    // defaultMenuIsOpen
                    // scale="small"
                  />
                </div>
              )}
              <div className={`filter-error-holder ${filterErrors[key] ? "is-visible" : "is-hidden"}`}>
                {(filterErrors[key] || []).map((error: string) => (
                  <span key={error} className="color-red">
                    {error}
                  </span>
                ))}
              </div>
            </div>
          ))
        )}
        {!!errors.mapFilters && <div style={{ fontSize: "1.2rem" }}>Unavailable</div>}
      </section>
      {Object.keys(others).length !== 0 && (
        <Fragment>
          <div className="pl24 pr24">
            <hr className="m0" />
          </div>
          <section>
            <h4>{t("Other Options")}</h4>
            {Object.keys(others).map((key: string) => {
              if (others[key].hidden) {
                return null;
              }
              return (
                <div key={others[key].name}>
                  <label className="checkbox mb8">
                    <input type="checkbox" checked={others[key].enabled} onChange={() => onOtherChange(key)} />
                    <span>{others[key].name}</span>
                  </label>
                </div>
              );
            })}
          </section>
        </Fragment>
      )}
    </aside>
  );
};

export default withTranslation("covid")(FiltersComponent);
