import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "@tscore/react-components";
// import Pagination from "../../../components/structure/pagination";
import { decodeParams, updateParams } from "../../../helpers/params";
import { useTranslation } from "react-i18next";
import { TabWeeklyPattern } from "./tab-weekly-pattern";
import { TabEscalationAlerts } from "./tab-escalation-alerts";
import { TabNhsrAlerts } from "./tab-nhsr-alerts";
import { TabPersonalisedAlerts } from "./tab-personalised-indicators";
//? TYPES:
import { ApplicationState } from "../../../store/reducers";
import { RouteComponentProps } from "react-router-dom";

const ALERT_TABS = ["weekly-work-pattern", "escalation-alerts", "personalised-indicators-alerts", "region-alerts"];

type AlertsProps = RouteComponentProps<any>;

export const Alerts: React.FC<AlertsProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const searchParams = decodeParams(location.search);
  const tab = searchParams.tab || ALERT_TABS[0];
  const customRba = useSelector((state: ApplicationState) => state.menuReducer.customRba);
  const onClickTabItem = (tab: { label: string; id?: string }) => {
    history.push({
      search: updateParams(location.search, { tab: tab.id as string, query: undefined, page: undefined }),
    });
    return false;
  };
  return (
    <div className="box">
      <h2>{t("myAccount:Alert Settings")}</h2>
      <Tabs isResponsive activeTab={t("myAccount:" + tab)} tabClicked={onClickTabItem}>
        <Tabs.Item label={t("myAccount:" + ALERT_TABS[0])} id={ALERT_TABS[0]}>
          <TabWeeklyPattern />
        </Tabs.Item>
        <Tabs.Item label={t("myAccount:" + ALERT_TABS[1])} id={ALERT_TABS[1]}>
          <TabEscalationAlerts history={history} location={location} match={match} />
        </Tabs.Item>
        <Tabs.Item label={t("myAccount:" + ALERT_TABS[2])} id={ALERT_TABS[2]}>
          <TabPersonalisedAlerts history={history} location={location} match={match} />
        </Tabs.Item>
        {customRba.CAN_ACCESS_REGION_ALERTS && (
          <Tabs.Item label={t("myAccount:" + ALERT_TABS[3])} id={ALERT_TABS[3]}>
            <TabNhsrAlerts />
          </Tabs.Item>
        )}
      </Tabs>
    </div>
  );
};
