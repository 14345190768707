import axios from "axios";
import { API } from "../actions/api.names";
import { accessDenied, apiError, apiStart, apiEnd, apiSuccess, FAKE_AXIOS_ERROR } from "../actions/api";
import { fetchAuthorizeIfNeeded, fetchRefreshToken } from "../actions/auth";
import { fetchMenu } from "../actions/auth/menu";
import i18n from "i18next";
import {
  CHECK_TOKEN_URL,
  LOG_IN_URL,
  VERIFY_TOTP_URL,
  AUTHENTICATION_401_IGNORE,
  REFRESH_SUFFIX,
} from "../actions/auth/consts";
import { refreshInfo } from "./api.refresh";
import { getTokens } from "../../get-tokens";
// ? TYPES:
import { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { ApiError, Success207, Response207, Error207 } from "../types/api";

(window as any).cancels = {};

function dealWithMultiStatusErrored(errored: any[], callback: (x: { code: number; message: ApiError }) => void) {
  if (errored.length > 0) {
    const code = errored[0].status; // ? assumes the status of first error is for all
    const found = Object.keys(errored[0]).filter((k) => k === "id" || k.startsWith("Id") || k.endsWith("Id"));
    const idKey: undefined | string = found[0];
    const message = errored.map((e: Error207) => {
      const field = idKey ? e[idKey] : undefined;
      return {
        error: e.message,
        field,
      };
    });
    callback({ code, message });
  }
}

const apiMiddleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any = { type: "" }) => {
    next(action);
    if (action.type !== API) return;

    const {
      url,
      method,
      data,
      skipAuthorization,
      onSuccess,
      onFailure,
      onEnd,
      label,
      headers,
      baseURL,
      responseType,
      onUploadProgress,
      onDownloadProgress,
      adapter,
      callbackParamName,
      transformResponse,
      other,
    } = action.payload;
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
    let ignoreFinally = false;

    // axios default configs
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE || "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept-Language"] = i18n.language;
    const accessToken = getTokens().access_token;
    if (accessToken && !skipAuthorization) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    } else if (axios.defaults.headers.common["Authorization"]) {
      delete axios.defaults.headers.common["Authorization"];
    }
    if (label) {
      dispatch(apiStart(label, other));
    }

    axios
      .request({
        baseURL,
        url,
        method,
        headers,
        [dataOrParams]: data,
        cancelToken: new axios.CancelToken((c: any) => {
          (window as any).cancels[label] = c;
        }),
        responseType,
        onUploadProgress,
        onDownloadProgress,
        adapter,
        callbackParamName,
        timeout: 60000,
        // ...(transformResponse && {
        //   transformResponse: [].concat(
        //     axios.defaults.transformResponse as any,
        //     transformResponse
        //   ),
        // }), // ? unfortunately, this still runs on error https://github.com/axios/axios/issues/1176
      } as AxiosRequestConfig & { callbackParamName?: string })
      .then((response: AxiosResponse<any>) => {
        const isMultiStatus = response.status === 207;
        const data = transformResponse ? transformResponse(response.data) : response.data;
        const [success, errored] = isMultiStatus ? data : [data, []];

        if ((isMultiStatus && success.length > 0) || !isMultiStatus) {
          onSuccess && dispatch(onSuccess(isMultiStatus ? [success, errored] : success, response.headers));
          dispatch(
            apiSuccess(label, method, response.status, {
              ...other,
              hasAnyErrors: errored.length > 0,
            })
          );
        }

        dealWithMultiStatusErrored(errored, ({ code, message }) => {
          dispatch(apiError(label, message, FAKE_AXIOS_ERROR({ code, method }), other));
          onFailure && dispatch(onFailure(message));
        });
        // ! if login do postlogin call, maybe do it somewhere else
        if (url === LOG_IN_URL || url === VERIFY_TOTP_URL) {
          dispatch(fetchAuthorizeIfNeeded(false));
        }
        if (url === CHECK_TOKEN_URL) {
          dispatch(fetchMenu());
        }
        // !
      })
      .catch((error: AxiosError) => {
        const statusTextForErrors = [503];
        const status = error.response?.status || -1;
        const message =
          error.response?.data.errors ||
          error.response?.data.error ||
          (statusTextForErrors.includes(status) && error.response?.statusText) ||
          error.response?.data.debugMessage ||
          error.response?.data.message ||
          error.response?.data.msg ||
          error.message;

        function onError() {
          dispatch(apiError(label, message, error, other));
          onFailure && dispatch(onFailure(message));
        }

        if (axios.isCancel(error)) {
          // do nothing if cancel
        } else if (status === 401 && AUTHENTICATION_401_IGNORE.includes(error.config.url!)) {
          onError();
        } else if (status === 401 && !action._retry) {
          if (error.config.url?.endsWith(REFRESH_SUFFIX) || refreshInfo.isExempt(baseURL)) {
            dispatch(accessDenied(window.location.pathname));
            onError();
          } else {
            ignoreFinally = true;
            action._retry = true;
            refreshInfo.requestUnauthorisedQueue.push(action);
            if (refreshInfo.getLastRefreshedAtDiff() >= 6000) {
              refreshInfo.setIsRefreshing(true);
              dispatch(fetchRefreshToken());
            }
          }
        } else {
          onError();
        }
      })
      .finally(() => {
        if (url.endsWith(REFRESH_SUFFIX)) {
          refreshInfo.reset();
        }
        refreshInfo.replayIfNeeded(dispatch);
        if (label && !ignoreFinally) {
          dispatch(apiEnd(label, other));
          onEnd && dispatch(onEnd());
        }
      });
  };

function transform207Response(data: Response207[]): [Success207[], Error207[]] {
  return data.reduce(
    ([success, errored], a) => {
      if (a.status === 200) {
        return [[...success, a.data], errored];
      }
      return [success, [...errored, a]];
    },
    [[], []] as [Success207[], Error207[]]
  );
  // return [
  //   data.filter((a: { status: number }) => a.status === 200).map((a: any) => a.data),
  //   data.filter((a: { status: number }) => a.status !== 200),
  // ];
}

axios.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    if (response.status === 207) {
      return {
        ...response,
        data: transform207Response(response.data),
      };
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default apiMiddleware;
