import { accessEnv } from "../access-env";
import { WINTER_REPORT_COSMETIC_NAME } from "../pages/reports/consts";
// const ORIGIN_URL = document.location.origin;
export const WAITLESS_URL = accessEnv("WAITLESS_BASE");
export const WAITLESS_ADMIN_URL = WAITLESS_URL + "/admin";

export const LOGIN_INTERNAL_URLS = {
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  activateAccount: "/activate-account",
  setupMfa: "/setup-mfa",
  enforceMfa: "/verify",
};

export const ELECTIVE_INTERNAL_URLS = {
  electivehome: "/elective/",
  electiveDashboardList: "/elective/dashboard",
  electiveMenuList: "/elective/map",
};

export const VANTAGE_INTERNAL_URLS = {
  vantagehome: "/vantage/",
  vantageDashboardList: "/vantage/dashboard",
  vantageMenuList: "/vantage/map",
  // dashboards
};

export const NHSR_INTERNAL_URLS = {
  nhsrhome: "/nhsr/",
  nhsrregionalReport: "/nhsr/reports/all/" + WINTER_REPORT_COSMETIC_NAME,
  nhsrotherReportOrganisationList: "/nhsr/reports",
  // ? generated & linked:
  nhsrdocumentOrganisationList: "/nhsr/documents",
  nhsrsystemDashboardList: "/nhsr/whole-system-resilience",
  nhsrtableauGlobalList: "/nhsr/tableau",
};

export const INTERNAL_URLS = {
  ...LOGIN_INTERNAL_URLS,
  ...NHSR_INTERNAL_URLS,
  ...ELECTIVE_INTERNAL_URLS,
  ...VANTAGE_INTERNAL_URLS,
  home: "/",
  covidSingle: "/covid",
  myAccount: "/my-account",
  help: "/help",
  powerbi: "/powerbi",
  // ? generated & linked:
  systemDashboardList: "/whole-system-resilience",
  manualUpdateOrganisationList: "/manual-updates",
  documentOrganisationList: "/documents",
  winterReportOrganisationList: "/reports",
  otherReportOrganisationList: "/reports",
  tableauGlobalList: "/tableau",
};

export const MODULE_URLS = {
  Resilience: INTERNAL_URLS.home,
  "NHS Region": INTERNAL_URLS.nhsrhome,
  Elective: INTERNAL_URLS.electivehome,
  Vantage: INTERNAL_URLS.vantagehome,
  WaitLess: WAITLESS_ADMIN_URL,
};
export enum MODULE_IDS {
  Resilience = "Resilience",
  "NHS Region" = "Region",
  Elective = "Elective",
  Vantage = "Vantage",
  WaitLess = "WaitLess",
}

export enum MODULE_IDS_SERVER {
  Resilience = "Resilience",
  "NHS Region" = "Region",
  Elective = "Elective",
  Vantage = "Vantage",
  WaitLess = "Waitless",
  Covid = "Covid",
  Prediction = "Prediction",
}

export type ModuleIdServerKey = keyof typeof MODULE_IDS_SERVER;
export type ModuleIdServer = (typeof MODULE_IDS_SERVER)[ModuleIdServerKey];

export const SYSTEM_DASHBOARD_URL_PATHS = [
  INTERNAL_URLS.systemDashboardList,
  INTERNAL_URLS.nhsrsystemDashboardList,
  INTERNAL_URLS.electiveDashboardList,
  INTERNAL_URLS.vantageDashboardList,
]; // ! for sidebar-list-extra-checks.ts
// export const MODULE_NAMES = {
//   Resilience: "Resilience",
//   "NHS Region": "Region",
//   Elective: "Elective",
// };

// type ValueOf<T> = T[keyof T];
// export type TModuleUrlValue = ValueOf<typeof MODULE_URLS>;
export type TModuleUrlKey = keyof typeof MODULE_URLS;
export type TInternalUrlKey = keyof typeof INTERNAL_URLS;
