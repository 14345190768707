import DocumentsService from "./documents.service";
import { cancelRequest } from "../../../helpers/cancel-request";
import { readableToBytes } from "../../../helpers/format-bytes";
import { paginate } from "../../../helpers/paginate";
import { NAMES } from "./NAMES";
// ? TYPES:
import { ApiAction } from "../../types/api";
import { PaginatedSort } from "../../../types/paginated-params";
import { EditDocumentMeta } from "../../../schemas/document-edit.schema";
import { DocumentUploadResponse } from "../../types/documents-state";
import { ApplicationState } from "../../../store/reducers";

const REDUCER_NAME = NAMES["DELETE_DOCUMENTS"] === "DELETE_DOCUMENTS" ? "documentsReducer" : "nhsrDocumentsReducer";

const getDocuments = (agencyId: number, page: number, data: any) => ({
  type: NAMES["GET_DOCUMENTS"],
  page,
  agencyId,
  data,
});

const uploadMultipleDocuments = (agencyId: number, data: any) => ({
  type: NAMES["UPLOAD_MULTIPLE_DOCUMENTS"],
  agencyId,
  data,
});

const uploadDocument = (agencyId: number, data: DocumentUploadResponse, originalFile: File) => ({
  type: NAMES["UPLOAD_DOCUMENT"],
  agencyId,
  data,
  originalFile,
});

export const updateDocumentUploadProgress = (agencyId: number, fileKey: string, percentage = 0) => ({
  type: NAMES["UPDATE_DOCUMENT_UPLOAD_PROGRESS"],
  agencyId,
  fileName: fileKey,
  percentage,
});

export const updateDocumentDownloadProgress = (agencyId: number, fileKey: string, percentage = 0) => ({
  type: NAMES["UPDATE_DOCUMENT_DOWNLOAD_PROGRESS"],
  agencyId,
  documentId: fileKey,
  percentage,
});

export const resetDocumentUploadProgress = (agencyId: number) => ({
  type: NAMES["RESET_DOCUMENT_UPLOAD_PROGRESS"],
  agencyId,
});

const downloadDocument = (agencyId: number, documentId: string, fileName: string, extension: string, data: string) => ({
  type: NAMES["DOWNLOAD_DOCUMENT"],
  agencyId,
  documentId,
  fileName,
  extension,
  data,
});

const deleteDocuments = (agencyId: number, data: Document[]) => ({
  type: NAMES["DELETE_DOCUMENTS"],
  agencyId,
  data,
});

const updateDocumentMeta = (agencyId: number, data: Document) => ({
  type: NAMES["UPDATE_DOCUMENT_META"],
  agencyId,
  data,
});

export const fetchUpdateDocumentMeta = (id: number, values: EditDocumentMeta, afterSuccess?: () => void): ApiAction => {
  return DocumentsService.updateDocumentMeta(
    {
      id,
      values,
    },
    {
      label: NAMES["UPDATE_DOCUMENT_META"],
      onSuccess: (response: any) => {
        if (afterSuccess) {
          afterSuccess();
        }
        return updateDocumentMeta(id, response);
      },
      other: { agencyId: id, documentId: values.documentId },
    }
  );
};

export const fetchDocuments = (
  id: number,
  page = 1,
  query?: string,
  sort?: PaginatedSort,
  lastPageCallback?: (page: number) => void
): ApiAction => {
  cancelRequest(NAMES["GET_DOCUMENTS"]);
  return DocumentsService.getDocumentList(
    {
      id,
      page,
      documentName: query,
      sort,
    },
    {
      label: NAMES["GET_DOCUMENTS"],
      onSuccess: (response: any) => {
        if (lastPageCallback && page > 1 && response.totalRecords > 0 && response.results.length === 0) {
          // if page is too high navigate to endPage
          const pagination = paginate(response.totalRecords, page, response.limit);
          lastPageCallback(pagination.endPage);
        }
        return getDocuments(id, page, response);
      },
      // onFailure: () => console.log("Error occured loading articles"),
      other: { agencyId: id },
    }
  );
};

export const fetchUploadMultipleDocuments = (id: number, files: File[]): ApiAction => {
  return DocumentsService.uploadDocuments(
    { id, files },
    {
      label: NAMES["UPLOAD_MULTIPLE_DOCUMENTS"],
      onSuccess: (response: any) => uploadMultipleDocuments(id, response),
      other: { agencyId: id },
    }
  );
};

export const fetchUploadDocument = (id: number, file: File) => {
  return (dispatch: any, _getState: () => ApplicationState) => {
    const onUploadProgress = (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch(
        updateDocumentUploadProgress(id, file.name + file.lastModified, percentCompleted >= 90 ? 90 : percentCompleted)
      );
      // console.log({ [file.name]: percentCompleted });
    };
    dispatch(
      DocumentsService.uploadDocuments(
        { id, files: [file] },
        {
          label: NAMES["UPLOAD_DOCUMENT"],
          onSuccess: (response: DocumentUploadResponse) => uploadDocument(id, response, file),
          other: { agencyId: id },
        },
        onUploadProgress
      )
    );
  };
};

export const fetchDownloadDocument = (
  id: number,
  documentId: string,
  fileName: string,
  extension: string,
  readableFileSize?: string
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const previousProgress = getState().documentsReducer[id].downloadProgress[documentId];
    dispatch(updateDocumentDownloadProgress(id, documentId, previousProgress === 100 ? 0 : 5));
    const percentToShow = (percentage: number): number => {
      if (percentage >= 90) {
        return 90;
      }
      if (percentage <= 10) {
        return 10;
      }
      return percentage;
    };
    const onDownloadProgress = (progressEvent: any) => {
      const total = progressEvent.total !== 0 ? progressEvent.total : readableToBytes(readableFileSize);
      const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
      // console.log({ x: progressEvent.srcElement.getResponseHeader("content-length") });
      dispatch(updateDocumentDownloadProgress(id, documentId, percentToShow(percentCompleted)));
      // console.log({ [documentId]: percentCompleted, total });
    };
    dispatch(
      DocumentsService.downloadDocument(
        { id, documentId, fileName },
        {
          label: NAMES["DOWNLOAD_DOCUMENT"],
          onSuccess: (response: any) => downloadDocument(id, documentId, fileName, extension, response),
        },
        onDownloadProgress
      )
    );
  };
};

// export const fetchDeleteDocuments = (id: number, documentIdList: string[]): ApiAction => {
//   return DocumentsService.deleteDocument(
//     {
//       id,
//       documentIdList,
//     },
//     {
//       label: NAMES["DELETE_DOCUMENTS"],
//       onSuccess: (response: any) => deleteDocuments(id, response),
//       other: { agencyId: id, documentIdList },
//     }
//   );
// };

export const fetchDeleteDocuments = (
  id: number,
  documentIdList: string[],
  lastPageCallback?: (page: number) => void
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const state = getState()[REDUCER_NAME][id];
    const { meta, documents } = state;
    dispatch(
      DocumentsService.deleteDocument(
        {
          id,
          documentIdList,
        },
        {
          label: NAMES["DELETE_DOCUMENTS"],
          onSuccess: (response: any[]) => {
            if (lastPageCallback) {
              const deletedCount = response.length;
              const currentPageCountAfter = documents.length - deletedCount;
              const totalCountAfter = meta.totalItems - deletedCount;
              if (meta.currentPage === 1 && totalCountAfter > 0) {
                const rndHACK = "_" + Math.random().toString(36).substring(2, 7).replace(/[0-9]/g, "");
                lastPageCallback((1 + rndHACK) as any);
              } else if (meta.currentPage > 1 && currentPageCountAfter === 0) {
                lastPageCallback(meta.currentPage - 1);
              }
            }
            return deleteDocuments(id, response);
          },
          other: { agencyId: id, documentIdList },
        }
      )
    );
  };
};
