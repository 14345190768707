import React from "react";
// import { Polygon } from "google-maps-react";
import { Polygon } from "../../../components/map/improved/Polygon";
import { mapCoordinatesToPolygon } from "../helpers/map";

export const MultiPolygon = (props: any): JSX.Element[] => {
  const paths: any[][][][] = props.paths;
  return paths.map((path: number[][][], index: number) => {
    const coordinates = mapCoordinatesToPolygon(path);
    return <Polygon {...props} key={index} paths={coordinates} />;
  });
};
