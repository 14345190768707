import React, { lazy, Suspense, useCallback, useEffect, useReducer } from "react";
// import TableauReport from "tableau-react";
// import { Button, Tippy } from "@tscore/react-components";
import { useSimpleApi } from "../../hooks/use-simple-api";
import { ThirdPartyCookieNotice } from "./thirdPartyCookieNotice";
import { SERVICE_URLS } from "../../globals/service-urls";
import { decodeParams } from "../../helpers/params";
import classNames from "classnames";
// import { Button, Dropdown } from "@tscore/react-components";
import Meta from "../meta";
import { TableauLoading, dangerCSS } from "./tableau-loading";
import { DEFAULT_OPTIONS, DEFAULT_QUERY, splitAtCapital } from "./consts";
import { TABLEAU_REPORT_ID, TABLEAU_FILTERS, TABLEAU_PARAMETERS } from "../../globals/tableau-settings";
// import { TableauEventName } from "./enums";
// ? TYPES:
import { RouteComponentProps } from "react-router-dom";
import { Viz, Workbook } from "../../types/tableau";
import { LazyComponent } from "../../types/lazy-component";

interface Params {
  // pretty?: string;
  tId: string;
  [key: string]: string;
}

type Props = RouteComponentProps<Params>;

interface TableauRef {
  container: HTMLDivElement;
  viz: Viz;
  workbook: Workbook;
}

interface State {
  isLoading: boolean;
  title: string;
  workbookName: string;
  activeSheetName: string;
  tableauStatus: "loading" | "success" | "error";
  expanded: boolean;
  node: TableauRef;
}

const LazyTableauReport = lazy(
  async () => import(/* webpackChunkName: "tableau-react" */ "tableau-react") as unknown as LazyComponent
);

// const x = tableau.TableauEventName.VIZ_RESIZE;

function hasReportErrored(node: TableauRef) {
  if (!node.viz._impl.$vizSize) {
    return true;
  }
  return false;
}

// function onResize(event: any) {
//   console.log({ event });
// }

const initialState: State = {
  isLoading: true,
  title: "-",
  workbookName: "",
  activeSheetName: "",
  tableauStatus: "loading",
  expanded: false,
  node: null as unknown as TableauRef,
};

function reducer(state: State, action: any): State {
  if (action.type === "RESET_STATE") {
    return initialState;
  }
  // if (action.type === "CHANGE_STATUS") {
  //   return {
  //     ...state,
  //     status: action.status,
  //   };
  // }
  if (action.type === "IFRAME_LOADED") {
    const node = action.node;
    // console.log("==IFRAME_LOADED");
    const hasErrored = hasReportErrored(node);
    // console.log(node);
    return {
      ...state,
      isLoading: false,
      tableauStatus: hasErrored ? "error" : state.tableauStatus,
      node, // ? dont need at the moment
    };
  }
  if (action.type === "REPORT_LOADED_SUCCESS") {
    // console.log("==onLoad", "SUCCESS");
    const title = splitAtCapital(action.title);
    const { workbook } = state.node;
    // const workbook = viz.getWorkbook();
    const activeSheet = workbook.getActiveSheet();
    return {
      ...state,
      isLoading: false,
      tableauStatus: "success",
      title: title,
      workbookName: workbook.$workbookImpl.$name,
      activeSheetName: activeSheet._impl.$name,
    };
  }
  if (action.type === "TOGGLE_EXPANDED") {
    return {
      ...state,
      expanded: !state.expanded,
    };
  }
  throw Error("Unknown action.");
}

export const TableauPage: React.FC<Props> = ({ match }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [isLoading, setIsLoading] = useState(true);
  const callbackTableauRef = useCallback(
    (node: TableauRef | null) => {
      if (node) {
        // console.log({ node });
        if (node.viz?._impl?.$iframe) {
          const iframe = node.viz._impl.$iframe;
          iframe.addEventListener(
            "load",
            () => {
              dispatch({ type: "IFRAME_LOADED", node });
            },
            { once: true }
          );
        }
      }
    },
    [dispatch]
  );
  const { tableauId } = match.params;
  useEffect(() => {
    return () => dispatch({ type: "RESET_STATE" });
  }, [dispatch, tableauId]);
  const tid = TABLEAU_REPORT_ID[tableauId];
  const searchParams = decodeParams(location.search);
  const { status, response, error } = useSimpleApi(
    {
      method: "POST",
      url: `${SERVICE_URLS["resilience-service"]}1/tableau/trusted-url/tableauId`,
      data: tid,
      headers: {
        "Content-Type": "text/plain",
      },
      label: "SIMPLE_GET_TABLEAU_TOKEN",
    },
    { defaultResponse: {} }
  );
  // console.log({ status });
  // console.log({ state });
  // useLayoutEffect(() => {
  //   console.log({ reportRef });
  //   if (reportRef.current) {
  //     const a = reportRef.current.workbook.$workbookImpl.$name;
  //     console.log({ a });
  //     console.log({ reportRef });
  //   }
  // }, [reportRef]);
  // const [reportSourceUrl] = match.url.split("/tableau");
  // const breadcrumbs = ["Dashboards", { name: "Bath & North East Somerset System Dashboard", to: reportSourceUrl }];
  const breadcrumbs: string[] = [];
  if (status === "error") {
    return (
      <main id="tableau-report">
        <Meta hideMeta title="Error" breadcrumbs={breadcrumbs} />
        <div className="tableau__holder" style={{ height: "auto", color: "red" }}>
          {error}
        </div>
        <style dangerouslySetInnerHTML={{ __html: dangerCSS }} />
      </main>
    );
  }
  if (status !== "success") {
    return <TableauLoading breadcrumbs={breadcrumbs} />;
  }
  return (
    <main id="tableau-report">
      <Meta hideMeta title={state.title} breadcrumbs={breadcrumbs} isFetching={state.tableauStatus === "loading"} />
      <div className={classNames("tableau__holder", { expanded: state.expanded })} style={{ padding: 0 }}>
        {/* <aside className="tableau__header">
          <div className="group">
            <Dropdown
              position="right"
              trigger={<Button scale="small" isOutlined colour="lightgray" length="short" icon="more_vert"></Button>}>
              {METHODS.map((n) => (
                <Dropdown.Item key={n} label={n} onItemClick={() => (state.node.viz as any)[n]()}></Dropdown.Item>
              ))}
            </Dropdown>
            <Button
              scale="small"
              isOutlined
              colour="lightgray"
              length="short"
              icon="fullscreen"
              onClick={() => dispatch({ type: "TOGGLE_EXPANDED" })}></Button>
          </div>
        </aside> */}
        {state.tableauStatus === "loading" && (
          <aside className="tableau-loader-holder">
            <i className="l xl tableau-custom" />
          </aside>
        )}
        <ThirdPartyCookieNotice enabled={state.tableauStatus === "error"} />
        <Suspense fallback={<TableauLoading breadcrumbs={breadcrumbs} />}>
          <LazyTableauReport
            ref={callbackTableauRef}
            onLoad={() => {
              dispatch({ type: "REPORT_LOADED_SUCCESS", title: tableauId });
              // if (tableauRef.current) {
              //   const iframe = tableauRef.current.container.querySelector("iframe");
              //   const hasError = iframe!.offsetHeight === IFRAME_ERROR_HEIGHT;
              //   console.log({ iframe });
              //   console.log({ hasError });
              // }
            }}
            url={response.url}
            filters={TABLEAU_FILTERS[tid](match.params, searchParams)}
            parameters={TABLEAU_PARAMETERS[tid](match.params, searchParams)}
            options={DEFAULT_OPTIONS}
            query={DEFAULT_QUERY}
          />
        </Suspense>
      </div>
      <style dangerouslySetInnerHTML={{ __html: dangerCSS }} />
    </main>
  );
};
