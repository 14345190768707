import React from "react";
import { Marker } from "../../../../components/map/improved/Marker";
import { hospitalIconAsDataUri } from "../icons/HospitalIcon.component";
import { BRAG_COLOURS, BRAG_PRESSURE_LEVEL_MAPPING } from "../../globals";
import { FACILITY_TYPE_TO_TEXT_MAPPING } from "../../../../components/map/consts";
import { FACILITY_ICON_SIZE } from "../../globals/settings";
// ? TYPES:
import { FacilityMarkerOptions, ExpandedMarker } from "../../types";

const DEFAULT_ZINDEX = 4;
interface Props {
  facilities: FacilityMarkerOptions[];
  onMouseover: Function;
  onMouseout: Function;
  currentFacilityHover: ExpandedMarker | null;
  onFacilityClick?: (facility: FacilityMarkerOptions) => void;
  // ieVersion: { type: "ie" | "edge" | null; version: number };
}

const ICON_WIDTH = FACILITY_ICON_SIZE.WIDTH + FACILITY_ICON_SIZE.STROKE_WIDTH * 2;
const ICON_HEIGHT = FACILITY_ICON_SIZE.BOX_HEIGHT + FACILITY_ICON_SIZE.BOX_OFFSET + FACILITY_ICON_SIZE.STROKE_WIDTH * 2;

const facilitiesList = ({
  facilities,
  onMouseover,
  onMouseout,
  currentFacilityHover,
  onFacilityClick,
}: // ieVersion,
Props): JSX.Element[] => {
  return facilities.map((facility: FacilityMarkerOptions) => {
    // console.log(facility);
    const zIndex = currentFacilityHover && currentFacilityHover.name === facility.title ? 100 : DEFAULT_ZINDEX;
    const colour = BRAG_PRESSURE_LEVEL_MAPPING[facility.pressureLevel];
    const bragOptions = BRAG_COLOURS[colour];
    const iconSettings = {
      text: FACILITY_TYPE_TO_TEXT_MAPPING[facility.type],
      backgroundColour: bragOptions.colour,
      colour: bragOptions.textColour,
      // ieVersion: ieVersion,
    };
    return (
      <Marker
        key={facility.title}
        name={facility.title}
        type={facility.type}
        onMouseover={onMouseover}
        onMouseout={onMouseout}
        onClick={() => !!onFacilityClick && onFacilityClick(facility)}
        position={{ lat: facility.lat, lng: facility.lng }}
        optimized={false}
        icon={{
          url: hospitalIconAsDataUri(iconSettings),
          size: [ICON_WIDTH, ICON_HEIGHT], // ie 11
          scaledSize: [ICON_WIDTH, ICON_HEIGHT], // ie 11
          // origin: [0, 0],
          // anchor: [ICON_WIDTH / 2, ICON_HEIGHT]
        }}
        zIndex={zIndex}
      />
    );
  });
};

export default facilitiesList;
